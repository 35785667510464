/* min breakpoints */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

/* max breakpoints */

@media (max-width: 575.98px) {
  #event-details {
    #details {
      #nav-event-details {
        &.position-sticky {
          position: relative !important;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
  #event-details {
    #details {
      #nav-event-details {
        .navbar-collapse {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
