.text-cl-13 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 300 !important;
    font-size: 0.814rem !important;
}

.text-cl-16 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 300 !important;
    font-size: 1rem !important;
}

.text-cl-18 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 300 !important;
    font-size: 1.125rem !important;
}

.text-cr-13 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.814rem !important;
}

.text-cr-14 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
}

.text-cr-16 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
}

.text-cr-18 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.125rem !important;
}

.text-cr-32 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 400 !important;
    font-size: 2rem !important;
}

.text-cb-14 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
}

.text-cb-18 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.125rem !important;
}

.text-cr-20 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.25rem !important;
}

.text-cb-16 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.text-cb-20 {
    font-family: "Calibri", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
}

.text-bb-20 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
}

.text-mb-16 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
}

.text-mb-18 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.125rem !important;
}

.text-mb-20 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
}

.text-mb-24 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.5rem !important;
}


.text-mm-26 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.625rem !important;
}

.text-mb-26 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.625rem !important;
}

.text-mb-28 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.75rem !important;
}

.text-mb-32 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 700 !important;
    font-size: 2rem !important;
}

.text-mm-28 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.75rem !important;
}

.text-mm-24 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
}

.text-mm-20 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
}

.text-mm-18 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.125rem !important;
}

.text-mm-16 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
}

.text-mm-15 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.9375rem !important;
}


.text-mm-14 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
}

.text-mb-14 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
}

.text-mm-13 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.814rem !important;
}

.text-mm-12 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
}

.text-mm-11 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.6875rem !important;
}

.text-mm-10 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.814rem !important;
}

.text-mr-10 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.814rem !important;
}

.text-mr-20 {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.25rem !important;
}

.text-nb-20 {
    font-family: "NovelPro", serif !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
}

.text-nb-28 {
    font-family: "NovelPro", serif !important;
    font-weight: 600 !important;
    font-size: 1.75rem !important;
}

.text-nb-32 {
    font-family: "NovelPro", serif !important;
    font-weight: 600 !important;
    font-size: 2rem !important;
}

.text-nb-38 {
    font-family: "NovelPro", serif !important;
    font-weight: 600 !important;
    font-size: 2.375rem !important;
}

.text-nb-80 {
    font-family: "NovelPro", serif !important;
    font-weight: 600 !important;
    font-size: 5rem !important;
}

.text-rr-13 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.8125rem !important;
}

.text-rr-14 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
}

.text-rr-16 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
}

.text-rr-17 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.0625rem !important;
}

.text-rr-18 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.125rem !important;
}

.text-rr-20 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.25rem !important;
}

.text-rr-20 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.25rem !important;
}

.text-rr-26 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.625rem !important;
}


.text-rr-32 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 2rem !important;
}

.text-rr-37 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.3125rem !important;
}

.text-rr-47 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.9375rem !important;
}

.text-rm-12 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
}

.text-rm-15 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.9375rem !important;
}

.text-rm-16 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
}

.text-rb-15 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 0.9375rem !important;
}


.text-rm-16 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
}

.text-rm-24 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
}


.text-rm-32 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 500 !important;
    font-size: 2rem !important;
}

.text-rb-18 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
}

.text-rb-22 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.375rem !important;
}

.text-rb-24 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.5rem !important;
}

.text-rb-28 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 1.75rem !important;
}


.text-rb-32 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 2rem !important;
}

.text-rb-38 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 2.375rem !important;
}

.text-rb-42 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 2.625rem !important;
}

.text-rb-48 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 3rem !important;
}

.text-rb-58 {
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 3.625rem !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}