/* min breakpoints */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  #stickyAreaNav,
  #devAreaNav {
    .sticky-fixme {
      display: none !important;
    }

    ul li {
      width: 33%;
      float: left;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

/* max breakpoints */

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  body:not(.is-ie) {
    #stickyAreaNav,
    #devAreaNav {
      .nav-link {
        &.mobile-only-one {
          &.active {
            ~ .sticky-fixme {
              display: none;
            }
          }

          &:not(.active) {
            display: none;
          }
        }
      }

      ul {
        ~ .sticky-fixme {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}
