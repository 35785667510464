#funding, #video-list-filters, .video-selected-filter,
#grants-filter-modal, #videos {
  z-index: 9999;

  .fundingEnd {
    color: #393e42;
    border-radius: 0 0 1rem 0;
  }

  .list-inline {
    font-size: 0.815rem;
  }

  .grants-filter-form {
    .card {
      border-radius: 1.5rem 0 1.5rem 0;
      border: none;

      .card-body {
        padding: 2.625rem 1.5rem;

        @include media-breakpoint-up(md) {
          padding: 2.625rem 2rem;
        }
      }
    }

    fieldset {
      display: flex;
      flex-direction: column;
      padding: 0 0 2rem 0;
      width: 100%;

      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-of-type {
        margin-bottom: 2rem;
      }

      &:not(:first-child) {
        margin-top: 2rem;
      }

      legend {
        font-family: "Calibri", sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0;
        text-align: left;
      }

      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
          &.extra-option {
            display: none;

            &.show {
              display: inherit !important;
            }
          }

          .custom-control {

            padding-left: 0;

            /* Bardzo brzydki workaround na te cholerne !important na każdym kroku */
            @include media-breakpoint-down(md) {
              .custom-control-label {
                &:before {
                  left: 0 !important;
                }

                &:after {
                  left: 0 !important;
                }
              }
            }

            .custom-control-label {
              padding-left: 1.75rem;
              font-size: 1.125rem;
              line-height: 1.375rem;

              &:before {
                left: 0;
              }

              &:after {
                left: 0;
              }

              .input-aggregation {
                color: $gray-600;
                font-size: 1rem;
                line-height: 1.25rem;
                margin-left: 0.75rem;

                .input-aggregation-value {
                  &:before {
                    content: "(";
                  }

                  &:after {
                    content: ")";
                  }
                }
              }
            }

            &.custom-radio {
              .custom-control-label {
                &.page-link {
                  background-color: transparent;
                  border: none;

                  &::before {
                    left: initial;
                  }

                  &::after {
                    left: initial;
                  }
                }
              }
            }

            &.custom-switch {
              .custom-control-label {
                &:before {
                  left: -6px;
                }

                &:after {
                  left: -4px;
                  top: calc(0.35rem + 2px) !important; // damn importants
                }
              }
            }
          }
        }
      }

      .more-options {
        text-decoration: none;

        &:hover, &:focus {
          text-decoration: none;
        }

        .more-icon {
        }

        .more-text {
          &:hover, &:focus {
            text-decoration: underline;
          }
        }
      }

      label {
        &.text-input {
          display: flex;
          align-items: center;

          .label-text {
            margin-right: 0.5rem;
          }
        }
      }

      .form-control {
        &.max-funding-input {
          width: 125px;
        }
      }

      .text-search {
        .text-search-input {
        }

        + button {
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1;
          padding: 0.5rem;
          border: none;
          border-radius: 0.25rem;
          background-color: $primary;

          &[disabled],
          &:disabled {
            background-color: $light;
            cursor: default;
          }
        }
      }

      &.infobox-holder,
      .infobox-holder {
        .infobox {
          display: flex;
          flex-direction: column;
          padding-left: 1.75rem;
          position: relative;

          &:before {
            content: escape-svg($infobox-icon);
            position: absolute;
            top: 0;
            left: 0;
          }

          button {
            text-decoration: underline;

            &:hover, &:focus {
              text-decoration: none;
            }
          }
        }

        .tooltip {
          opacity: 1;

          .tooltip-inner {
            padding: 1rem 0 1rem 1rem;
          }

          .tooltip-close-holder {
            .tooltip-close {
              padding: .5rem;
              margin: .5rem .5rem 0 .5rem;
            }
          }

          @include media-breakpoint-down(md) {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            width: 100%;

            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);

            > .d-flex {
              flex-direction: column-reverse !important;
            }

            .tooltip-inner {
              max-width: none;
              box-shadow: none !important;
              background: transparent;

              > p {
                text-align: left;
              }
            }
            .tooltip-close-holder {
              display: flex;
              flex-direction: row-reverse;
              padding: .5rem;
              background: transparent;

              .tooltip-close {
                padding: 0.5rem;

                svg {
                }
              }
            }
          }
        }
      }
    }

    .clear-all {
      text-decoration: underline;

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }

  #active-filters {
    padding: 0 1.5rem;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    .active-filters-header {
      font-family: "Calibri", sans-serif;
      font-size: 22px;
      font-weight: 700;
    }

    .number-of-results {
      font-weight: 700;
    }

    #active-filter-list {
      margin-left: -1rem;

      @include media-breakpoint-up(md) {
        margin-left: 0;
      }

      button {
        display: flex;
        align-items: center;
        background-color: $lightGrey;
        border: none;
        border-radius: 1.5rem;
        color: black;
        font-family: Calibri, sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 0.25rem 0.75rem;
        margin-bottom: 1rem;
        margin-left: 1rem;

        &:hover, &:focus {
          background-color: darken($lightGrey, 10%);
        }

        @include media-breakpoint-up(md) {
          padding: 0.5rem 0.75rem;
        }

        .button-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
          }

          .active-filter-name {
            font-weight: 700;
          }

          .active-filter-value {
            @include media-breakpoint-up(md) {
              margin-left: .25rem;
            }
          }
        }
      }
    }

    .clear-all {
      display: flex;
      align-items: center;
    }
  }

  #active-filter-stats-and-sort {
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin-top: 1.5rem;
    padding: 2rem 0;

    @include media-breakpoint-up(md) {
      border-top: none;
      margin-top: 0;
      padding: 0;
    }

    .dropdown-menu li.active span,
    .dropdown-menu li a:active span {
      color: white;
    }

  }

  .select-order {
    width: 120px;
  }

  button[data-toggle="modal"][data-target="#grants-filter-modal"],
  button#grants-filter-form-submit {
    border-radius: .25rem;
    padding: .75rem 2.625rem;
    font-weight: 700;
    font-family: "Calibri", sans-serif;
    font-size: 1.125rem;
  }

  .remove-icon {
    background: escape-svg($active-filter-icon);
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
    top: -1px;
    margin-left: .5rem;
  }

  button {
    &[disabled],
    &:disabled {
      .remove-icon {
        background: escape-svg($active-filter-icon-disabled);
      }
    }
  }
}

// Tylko poza modalem
#funding {
  #grants-filter-form {
    fieldset {
      @include media-breakpoint-down(md) {
        border-bottom: none !important;
        padding-bottom: 0 !important;
      }
    }
  }
}

// Tylko w modalu
#grants-filter-modal {
  button[data-dismiss="modal"] {

  }

  #grants-filter-modal-form {
    .card {
      border-radius: 0 !important;
    }
  }
}

.grants-items {
  --item-bg: $primary;

  a {
    &:hover {
      text-decoration: none;

      p.text-black,
      h6.text-black,
      p.text-info,
      li.text-light,
      p.text-parp {
        color: #fff !important;
      }
    }
  }

  .card {
    border: none;
    border-radius: 1rem 0 1rem 0;

    &:hover {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);

      .text-hover-white {
        color: $white !important;
      }
    }

    hr {
      border-color: black !important;
    }

    .img-container {
      > img {
        border-radius: 1rem 0 1rem 0;
        aspect-ratio: 1.74;
        object-fit: cover;
      }

      .grant-ending-alert,
      .grant-promoted-alert {
        background-color: var(--item-bg);
        border-radius: 1rem 0 1rem 0;
        position: absolute;

        padding: .75rem 1rem .5rem;

        &:empty {
          display: none;
        }
      }

      .grant-ending-alert {
        bottom: 0;
        right: 0;
      }

      .grant-promoted-alert {
        top: 0;
        left: 0;
      }
    }
  }
}

#events-results .eventContent p.text-black {
  color: #000 !important;
}

.results {
  .wow {
    /** Wow psuje taba */
    visibility: visible !important;
  }
}
