.typeahead__container button, .typeahead__container input, .typeahead__container optgroup, .typeahead__container select, .typeahead__container textarea {
  font: inherit;
  margin: 0
}

.typeahead__container optgroup {
  font-weight: 700
}

.typeahead__container button, .typeahead__container input {
  overflow: visible
}

.typeahead__container button, .typeahead__container select {
  text-transform: none
}

.typeahead__container [type=reset], .typeahead__container [type=submit], .typeahead__container button, .typeahead__container html [type=button] {
  -webkit-appearance: button
}

.typeahead__container [type=button]::-moz-focus-inner, .typeahead__container [type=reset]::-moz-focus-inner, .typeahead__container [type=submit]::-moz-focus-inner, .typeahead__container button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

.typeahead__container [type=button]:-moz-focusring, .typeahead__container [type=reset]:-moz-focusring, .typeahead__container [type=submit]:-moz-focusring, .typeahead__container button:-moz-focusring {
  outline: 1px dotted ButtonText
}

.typeahead__container fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

.typeahead__container legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

.typeahead__container textarea {
  overflow: auto
}

.typeahead__container [type=checkbox], .typeahead__container [type=radio] {
  box-sizing: border-box;
  padding: 0
}

.typeahead__container [type=number]::-webkit-inner-spin-button, .typeahead__container [type=number]::-webkit-outer-spin-button {
  height: auto
}

.typeahead__container [type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

.typeahead__container [type=search]::-webkit-search-cancel-button, .typeahead__container [type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

.typeahead__container ::-webkit-input-placeholder {
  color: inherit;
  opacity: .54
}

.typeahead__container ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

.typeahead__container {
  position: relative;
  font: 1rem Lato, Helvetica Neue, Arial, Helvetica, sans-serif
}

.typeahead__container * {
  box-sizing: border-box;
  outline: 0
}

.typeahead__query {
  position: relative;
  z-index: 2;
  width: 100%
}

.typeahead__filter {
  position: relative
}

.typeahead__filter button {
  min-width: 100%;
  white-space: nowrap
}

.typeahead__filter button:after {
  display: inline-block;
  margin-left: 4px;
  width: 0;
  height: 0;
  vertical-align: -2px;
  content: "";
  border: 4px solid;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent
}

.typeahead__field {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%
}

.typeahead__button button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px
}

.typeahead__field {
  color: #555
}

.typeahead__field .typeahead__hint, .typeahead__field [contenteditable], .typeahead__field input, .typeahead__field textarea {
  display: block;
  width: 100%;
  line-height: 1.25;
  min-height: calc(.5rem * 2 + 1.25rem + 2px);
  padding: .5rem .75rem;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px 0 0 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box
}

.typeahead__field .typeahead__hint:active, .typeahead__field .typeahead__hint:focus, .typeahead__field [contenteditable]:active, .typeahead__field [contenteditable]:focus, .typeahead__field input:active, .typeahead__field input:focus, .typeahead__field textarea:active, .typeahead__field textarea:focus {
  border-color: #66afe9
}

.typeahead__field input[type=search], .typeahead__field input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none
}

.typeahead__field input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0
}

.typeahead__container.hint .typeahead__field [contenteditable], .typeahead__container.hint .typeahead__field input, .typeahead__container.hint .typeahead__field textarea {
  background: transparent
}

.typeahead__container.hint .typeahead__query > :last-child, .typeahead__hint {
  background: #fff
}

.typeahead__container button {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  line-height: 1.25;
  padding: .5rem .75rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #555
}

.typeahead__container button:focus, .typeahead__container button:hover {
  color: #3c3c3c;
  background-color: #f5f5f5;
  border-color: #b3b3b3
}

.typeahead__container button.active, .typeahead__container button:active {
  background-image: none
}

.typeahead__container button:active, .typeahead__container button:focus {
  border-color: #66afe9
}

.typeahead__container button.disabled, .typeahead__container button[disabled], .typeahead__container input.disabled, .typeahead__container input[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  box-shadow: none;
  background-color: #fff;
  border-color: #ccc
}

.typeahead__container .typeahead__field .typeahead__hint, .typeahead__container .typeahead__field .typeahead__label-container, .typeahead__container .typeahead__field [contenteditable], .typeahead__container .typeahead__field input, .typeahead__container .typeahead__field textarea {
  padding-right: 32px
}

.typeahead__button, .typeahead__filter {
  z-index: 1
}

.typeahead__button button, .typeahead__filter button {
  margin-left: -1px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.typeahead__button:active, .typeahead__button:active button:active, .typeahead__button:active button:focus, .typeahead__button:focus, .typeahead__button:focus button:active, .typeahead__button:focus button:focus, .typeahead__button:hover, .typeahead__button:hover button:active, .typeahead__button:hover button:focus, .typeahead__filter:active, .typeahead__filter:active button:active, .typeahead__filter:active button:focus, .typeahead__filter:focus, .typeahead__filter:focus button:active, .typeahead__filter:focus button:focus, .typeahead__filter:hover, .typeahead__filter:hover button:active, .typeahead__filter:hover button:focus {
  z-index: 5
}

.typeahead__filter + .typeahead__button button {
  margin-left: -2px
}

.typeahead__container.filter .typeahead__filter {
  z-index: 5
}

.typeahead__dropdown, .typeahead__list {
  position: absolute;
  left: 0;
  z-index: 4;
  width: 100%;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-clip: padding-box
}

.typeahead__result.detached .typeahead__list {
  position: relative;
  z-index: 6;
  top: auto;
  left: auto
}

.typeahead__dropdown {
  right: 0;
  left: auto;
  z-index: 5
}

.typeahead__list > li {
  position: relative;
  border-top: 1px solid #ccc
}

.typeahead__list > li:first-child {
  border-top: none
}

.typeahead__dropdown .typeahead__dropdown-item[disabled] > a, .typeahead__list .typeahead__item[disabled] > a {
  cursor: not-allowed;
  color: #bababa;
  background-color: #fafafa
}

.typeahead__dropdown .typeahead__dropdown-item > a, .typeahead__list .typeahead__item > a {
  display: block;
  padding: .5rem .75rem;
  clear: both;
  color: #333;
  text-decoration: none
}

.typeahead__dropdown .typeahead__dropdown-item:not([disabled]).active > a, .typeahead__dropdown .typeahead__dropdown-item:not([disabled]) > a:focus, .typeahead__dropdown .typeahead__dropdown-item:not([disabled]) > a:hover, .typeahead__list .typeahead__item:not([disabled]).active > a, .typeahead__list .typeahead__item:not([disabled]) > a:focus, .typeahead__list .typeahead__item:not([disabled]) > a:hover {
  background-color: #f5f5f5;
  color: #3c3c3c
}

.typeahead__list.empty > li {
  padding: .5rem .75rem;
  color: #333
}

.typeahead__list > .typeahead__group {
  border-color: #bfdef6;
  font-weight: 700
}

.typeahead__list > .typeahead__group:first-child {
  border-top: 1px solid #bfdef6
}

.typeahead__list > .typeahead__group.active > a, .typeahead__list > .typeahead__group > a, .typeahead__list > .typeahead__group > a:focus, .typeahead__list > .typeahead__group > a:hover {
  cursor: default;
  color: #17639f;
  background: #ecf5fc;
  display: block;
  padding: .5rem .75rem;
  clear: both;
  text-decoration: none
}

.typeahead__list > li.typeahead__group + li.typeahead__item {
  border-color: #bfdef6
}

.typeahead__container.backdrop + .typeahead__backdrop, .typeahead__container.filter .typeahead__dropdown, .typeahead__container.hint .typeahead__hint, .typeahead__container.result .typeahead__list {
  display: block !important
}

.typeahead__container + .typeahead__backdrop, .typeahead__container .typeahead__dropdown, .typeahead__container .typeahead__hint, .typeahead__container .typeahead__list {
  display: none !important
}

.typeahead__dropdown li:last-child {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #ccc
}

.typeahead__cancel-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  right: 0;
  cursor: pointer;
  line-height: 1.25;
  padding: .5rem .75rem;
  visibility: hidden
}

.typeahead__label .typeahead__cancel-button {
  visibility: visible;
  right: 4px
}

.typeahead__container.cancel:not(.loading) .typeahead__cancel-button, .typeahead__label .typeahead__cancel-button {
  visibility: visible
}

.typeahead__container.cancel:not(.loading) .typeahead__cancel-button:hover, .typeahead__label .typeahead__cancel-button:hover {
  color: #d0021b
}

.typeahead__search-icon {
  padding: 0 1.25rem;
  width: 16px;
  height: 16px;
  background: url(data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1MC4zMTMgMjUwLjMxMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjUwLjMxMyAyNTAuMzEzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCI+CjxnIGlkPSJTZWFyY2giPgoJPHBhdGggc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgZD0iTTI0NC4xODYsMjE0LjYwNGwtNTQuMzc5LTU0LjM3OGMtMC4yODktMC4yODktMC42MjgtMC40OTEtMC45My0wLjc2ICAgYzEwLjctMTYuMjMxLDE2Ljk0NS0zNS42NiwxNi45NDUtNTYuNTU0QzIwNS44MjIsNDYuMDc1LDE1OS43NDcsMCwxMDIuOTExLDBTMCw0Ni4wNzUsMCwxMDIuOTExICAgYzAsNTYuODM1LDQ2LjA3NCwxMDIuOTExLDEwMi45MSwxMDIuOTExYzIwLjg5NSwwLDQwLjMyMy02LjI0NSw1Ni41NTQtMTYuOTQ1YzAuMjY5LDAuMzAxLDAuNDcsMC42NCwwLjc1OSwwLjkyOWw1NC4zOCw1NC4zOCAgIGM4LjE2OSw4LjE2OCwyMS40MTMsOC4xNjgsMjkuNTgzLDBDMjUyLjM1NCwyMzYuMDE3LDI1Mi4zNTQsMjIyLjc3MywyNDQuMTg2LDIxNC42MDR6IE0xMDIuOTExLDE3MC4xNDYgICBjLTM3LjEzNCwwLTY3LjIzNi0zMC4xMDItNjcuMjM2LTY3LjIzNWMwLTM3LjEzNCwzMC4xMDMtNjcuMjM2LDY3LjIzNi02Ny4yMzZjMzcuMTMyLDAsNjcuMjM1LDMwLjEwMyw2Ny4yMzUsNjcuMjM2ICAgQzE3MC4xNDYsMTQwLjA0NCwxNDAuMDQzLDE3MC4xNDYsMTAyLjkxMSwxNzAuMTQ2eiIgZmlsbD0iIzU1NTU1NSIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) no-repeat scroll 50% transparent
}

.typeahead__container.loading .typeahead__query:after, .typeahead__container.loading .typeahead__query:before {
  transition: all 0s linear, opacity .2s ease;
  position: absolute;
  z-index: 3;
  content: "";
  top: 50%;
  right: .55em;
  margin-top: -.675rem;
  width: 1.35rem;
  height: 1.35rem;
  box-sizing: border-box;
  border-radius: 500rem;
  border-style: solid;
  border-width: .1em
}

.typeahead__container.loading .typeahead__query:before {
  border-color: rgba(0, 0, 0, .35)
}

.typeahead__container.loading .typeahead__query:after {
  animation: a .6s linear;
  animation-iteration-count: infinite;
  border-color: #fff transparent transparent;
  box-shadow: 0 0 0 1px transparent
}

@keyframes a {
  0% {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}

.typeahead__label-container {
  list-style: none;
  position: absolute;
  padding-top: 0.375rem;
  padding-left: 6px;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -ms-flexbox;
  display: flex
}

.typeahead__label {
  display: -ms-flexbox;
  display: flex;
  font-size: 0.875rem;
  position: relative;
  background: #ecf5fc;
  border: 1px solid #c2e0ff;
  padding-left: 4px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 0.375rem
}

.typeahead__label > * {
  -ms-flex-item-align: center;
  align-self: center
}

.typeahead__label .typeahead__cancel-button {
  line-height: normal;
  height: auto;
  position: static;
  padding: calc(1rem * .25 - 1px) 6px calc(1rem * .25 + 1px);
  margin-left: 4px;
  font-size: 0.875rem;
  border-left: 1px solid #c2e0ff
}

.typeahead__label .typeahead__cancel-button:hover {
  background-color: #d5e9f9
}
