.custom-control {
  &.custom-radio {
    input {
      &:checked {
        ~ .custom-control-label {
        }
      }

      ~ .custom-control-label {
        &::after {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%27-5%20-5%2010%2010%27%3E%3Ccircle%20r%3D%273%27%20stroke-width%3D%271%27%20stroke%3D%27%23" + color-value($white) + "%27%20fill-opacity%3D%270%27%2F%3E%3C%2Fsvg%3E") !important;
          background-size: 100% 100%;
        }
      }

      &:focus ~ label {
        outline: Highlight solid 2px;
        @media (-webkit-min-device-pixel-ratio: 0) {
          outline: -webkit-focus-ring-color auto 5px;
        }
      }
    }
  }

  &.custom-checkbox {
    input {
      &:checked {
        ~ .custom-control-label {
          &::before {
            border: 1px solid $red;
            background-color: transparent;
          }

          &::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%208%208%27%3E%3Cpath%20fill%3D%27%23" + color-value($red) + "%27%20d%3D%27M6.564.75l-3.59%203.612-1.538-1.55L0%204.26%202.974%207.25%208%202.193z%27%2F%3E%3C%2Fsvg%3E") !important;
          }
        }
      }

      ~ .custom-control-label {
        &::before {
          background-color: transparent;
          border: 1px solid $gray-500;
        }
      }

      &:focus ~ label {
        outline: Highlight solid 2px;
        @media (-webkit-min-device-pixel-ratio: 0) {
          outline: -webkit-focus-ring-color auto 5px;
        }
      }
    }
  }

  &.custom-switch {
    input {
      &:focus ~ label {
        outline: Highlight solid 2px;
        @media (-webkit-min-device-pixel-ratio: 0) {
          outline: -webkit-focus-ring-color auto 5px;
        }
      }
    }
  }

  .custom-control-label {
    font-size: 0.875rem;
    color: #000;
    border-radius: 0;
    border-color: $white;
    border-width: 2px;
    padding: 0;
    margin-left: -1px;
    margin-bottom: 0;
    line-height: 1.5;
  }
}
