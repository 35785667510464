form.contact-parp-form {
  .row {
    flex-direction: column;
    margin: 0;
  }

  .form-inline {
    display: block;

    label {
      display: block;
    }
  }

  .bfElemWrap {
    &:last-of-type {
      .form-group {
        direction: rtl;
        float: left;

        label {
          margin-left: 0.75rem;
        }
      }
    }
  }

  .form-control {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e1e1;
    padding-left: 0;
  }

  .form-group {
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-left: 0;
    }
  }

  .form-group-actions {
    padding-top: 2rem;
    text-align: center;

    .btn {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  span[data-toggle="tooltip"] {
    display: inline !important;
  }
}
