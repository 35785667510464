.bar-background {
  background-color: rgb(242, 242, 242);
  background-size: cover;
  height: $top_banner_height;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5001;

  .promotion-top-bar {
    display: block;
    height: $top_banner_height;

    &:focus-visible {
      box-shadow: inset 0 0 0 3px #0060df;
      outline: none;
    }
  }
}

body.has-top-bar {
  .navbar.fixed-top {
    top: $top_banner_height;
  }

  #container-search .fixed-top {
    top: $top_banner_height;
  }

  #nav-grant,
  #nav-event-details,
  #stickyAreaNav,
  #devAreaNav {
    top: 138px;
  }
}

@include media-breakpoint-down(lg) {
  .bar-background {
    position: absolute !important;
  }

  body.has-top-bar {
    .navbar.fixed-top {
      margin-bottom: $top_banner_height;
    }
  }
}

/**
Przesunięcie elementów pod fixed menu
 */
@include media-breakpoint-up(xl) {
  body.has-top-bar {
    padding-top: calc(68px + #{$top_banner_height});
  }
}
