// Widget

.faq-widget button,
.faq-widget input,
.faq-widget select,
.faq-widget textarea {
  font-family: Calibri, sans-serif;
}

.faq-widget input:active,
.faq-widget input:focus,
.faq-widget textarea:active,
.faq-widget textarea:focus {
  outline: 0;
}

button:hover {
  cursor: pointer;
}

.faq-widget {
  font-family: Calibri !important;
  font-size: 1rem;
  font-weight: 400;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 9999;
  overflow-y: auto;
  max-height: 100%;
}

.faq-widget-open {
  display: none;
  margin: 10px;
  margin-bottom: 66px;
  border: 0 solid #f2f2f2;
  background-color: #fff;
  position: relative;
  right: 20px;
  -webkit-box-shadow: 4px 14px 32px 1px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 4px 14px 32px 1px rgba(0, 0, 0, 0.52);
  box-shadow: 4px 14px 32px 1px rgba(0, 0, 0, 0.52);
  transition: all 0.2s linear;
  width: 400px;
}

@media (min-width: 1600px) {
  .faq-widget-open {
    width: 440px;
  }
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 1s linear;
}

#chat_invite {
  height: auto;
  background-color: rgba(182, 25, 40, 0.8);
  border: 1px solid #fff;
  color: #fff;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-right: 10px;
  padding: 15px;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  bottom: 28px;
  box-shadow: 2px 1px 5px -1px rgba(0, 0, 0, 0.52);
  right: 100px;
}

#chat_open {
  background-image: url(../../../img/widget/bot.png);
  background-size: contain;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.52);
  border: 3px solid #fff;
}

#close_widget {
  background-color: rgba(182, 25, 40, 0.8);
  box-shadow: 2px 1px 5px -1px rgba(0, 0, 0, 0.52);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 20px;
  bottom: 78px;
  width: 20px;
  height: 20px;
  background-image: url(../../../img/widget/X.png);
  cursor: pointer;
  border-radius: 50%;
  border: 0 solid transparent;
}

.faq-widget-header {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 10px;
  text-align: center;
  background-color: #b51a29;
  color: #fff;

  @media (max-width: 480px) {
    padding: 14px 16px 8px 16px;
  }

  @media (max-width: 1600px) {
    padding: 12px 16px 10px 16px;
  }

  .faq-window-title-separator {
    width: 1px;
    background-color: #cd8289;
    margin: 0 10px;

    @media (max-width: 400px) {
      margin: 0 5px;
    }
  }
}

#faq-widget-close {
  cursor: pointer;
  margin-left: auto;
  @media (min-width: 400px) {
    position: relative;
    top: -8px;
    right: -10px;
  }
}

.faq-widget-chat {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 10px 0 0 0;
  box-sizing: border-box;
}

.faq-widget-chat-message {
  margin-right: 15px;
  position: relative;
  display: flex;
  align-items: center;
}

.head {
  margin-left: 15px;
  position: relative;
  top: 10px;
  text-align: center;
  padding: 10px;
}

.head img {
  clip-path: circle(27px at center);
  width: 60px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.head p {
  position: relative;
  margin: 0;
}

.faq-widget-chat-message .message {
  position: relative;
  background: #f3f4f5;
  border-radius: 0.4em;
  width: 70%;
  max-width: 70%;
  margin: 5px;
  margin-left: 20px;
  padding: 1px 15px;
}

.faq-widget-chat-message .message button {
  font-size: 14px;
  border: 1px solid #565b5d;
  padding: 3px 4px;
  margin: 3px;
  margin-top: 8px;
  border-radius: 5px;
  background-color: #f5f5f5;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.faq-widget-chat-message .message p {
  margin: 10px 0;
}

.faq-widget-chat-message .message:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  margin-top: -10px;
}

.faq-widget-chat-message.msg-bot .message {
  border: 1px solid #dcdcdc;
  background-color: #fff;
}

.faq-widget-chat-message.msg-bot .message:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  margin-top: -10px;
  left: 0;
  margin-left: -20px;
  border-right-color: #dcdcdc;
}

.faq-widget-chat-message.msg-bot .message:after {
  left: 0;
  margin-left: -16px;
  margin-top: -8px;
  border: 8px solid transparent;
  border-right-color: #fff;
}

.faq-widget-chat-message.msg-user {
  padding-left: 20px;
}

.faq-widget-chat-message.msg-user .message {
  margin-left: auto;
}

.faq-widget-chat-message.msg-user .message:after {
  right: 0;
  border-right: 0;
  margin-right: -10px;
  border-left-color: #dcdcdc;
}

.message button {
  font-size: 14px;
  border: 1px solid #565b5d;
  padding: 3px 4px;
  margin: 3px;
  margin-top: 8px;
  border-radius: 5px;
  background-color: #f5f5f5;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.faq-widget-coms {
  border: 1px solid #e6f1f4;
  margin: 10px;
  padding: 0 10px 0 5px;
}

.faq-widget-coms form {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.typeahead__container {
  width: 100%;
}

.typeahead__field {
  border-color: transparent !important;
  box-shadow: none !important;
  outline-offset: 0 !important;
}

.typeahead__query {
  z-index: 99999;
}

#faq-searchphrase {
  border-color: transparent;
  box-shadow: none;
}

.typeahead__field input {
  border: 0;
  outline: 0;
  outline-offset: 0;
  z-index: 99999;
}

.typeahead__button {
  display: flex;
  align-items: center;
  z-index: 99999;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: 0 0;
  border: 0;
}

.typeahead__list {
  display: block !important;
  overflow: auto;
  height: 110px;
  z-index: 99999;
}

.faq-widget-coms form button {
  border: 0;
  background: 0 0;
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.faq-widget-links {
  text-align: center;
  color: #000;
  font-size: 14px;
}

.faq-widget-links a {
  color: #b51a29;
  text-decoration: none;
}

.shown {
  animation: none !important;
}

@media screen and (max-width: 500px) {
  #chat_invite {
    width: 50vw;
    text-align: center;
  }

  .faq-widget-open {
    right: 0;
    width: 85vw;
  }

  .faq-window-icon-title__icon {
    // width: 32px;
    // height: 32px;
  }
}

@media screen and (max-width: 600px) {
  #chat_invite {
    width: 50vw;
    text-align: center;
  }
}

@media screen and (max-height: 680px) {
  .faq-widget-open {
    margin-bottom: 26px;
  }
}

@media screen and (max-height: 500px) {
  .faq-widget {
    max-height: 100vh;
  }

  .typeahead__display {
    font-size: 95%;
  }
}
