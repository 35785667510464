@include media-breakpoint-up(xl) {
  body {
    padding-top: 68px;
  }
}

.navbar {
  padding: 0;
}

.navbar-brand {
  margin-right: 0;
}

.featured-2023 .navbar-brand {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.featured-2023 .navbar-nav {
  @include media-breakpoint-up(xl) {
    //margin-left: 57px;

    &.flex-column {
      margin-left: 0;
    }
  }
}

.featured-2023 .navbar-collapse.navbar-collapse-visible {
  @include media-breakpoint-up(xl) {
    margin-left: 1.5rem;
  }
}

.navbar.fixed-top {
  z-index: 5000;
}

nav.navbar .dropdown-toggle::after {
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-nav .nav-item.show .nav-link {
  color: #b51a29;
}

li.nav-item {
  padding-top: 7px;
  padding-left: 7px;
  padding-right: 7px;
}

.nav-link {
  padding: 0;
  text-transform: uppercase;
  line-height: 17px;
  font-size: 17px;
  font-family: "Calibri";
  font-weight: 500;
  color: black;
}

.nav-item.show {
  background: #ffffff;
  border-left: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @include media-breakpoint-down(xl) {
    border-right: none;
    border-top: none;
  }
}

li.nav-item:last-child {
  border: none;
}

.navbar-small {
  flex-direction: row;
  margin-bottom: 3px;

  @include media-breakpoint-down(lg) {
    position: absolute;
    right: 40px;
    top: 15px;
    overflow-y: hidden;
  }

  @include media-breakpoint-down(sm) {
    top: 8px;

    .openSearch svg {
      width: 10px;
    }
  }
}

.navbar-small .nav-item,
.nav-item.small-item {
  padding: 11px 0;
}

.navbar-small,
.small-item {
  .nav-link {
    border: none;
    font-size: 0.855rem;
  }
}

.small-item {
  .nav-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navbar-small {
  .nav-search {
    position: relative;

    svg {
      /*	position: absolute;
    top: -3px;*/
    }
  }
}

.navbar-expand-md .navbar-nav .dropdown-menu {
  top: 65px;
  background: #f3f4f6;
  border: none;
}

.openSearch svg {
  top: 14px;
}

.navbar-about {
  background-color: #f7f7f7;
}

.navbar-about .nav-link {
  font-size: 1.25rem;
  text-transform: none;
}

.navbar-about .nav-link.active {
  border: 1px solid #f1edf1;
  background: #fff;
  color: var(--primary);
  border-radius: 5px;
  box-shadow: 0 0 0.3rem 0.05rem rgba(0, 0, 0, 0.05) !important;
}

.navbar-about .nav-link:hover {
  border: 1px solid #f1edf1;
  background: #f7f7f7;
}


/** nowy bur-2023 **/
.featured-2023.bur-2023 {
  $modal-text-color: #212529;
  $modal-font-size: 0.875rem;
  $modal-line-height: 1.3125rem;

  $modal-border-color: #D9D9D9;

  padding-top: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #4A4A4A;

  .bg-bur {
    background-color: #921420;
  }

  .btn-bug:hover path {
    stroke: white;
  }

  .btn {
    border-radius: 5px;
  }


  .bar-background {
    display: none;
  }

  .navbar {
    padding: .5rem 1.125rem .75rem 1.125rem;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 1rem;

    justify-content: flex-end;
    gap: 0.625rem;

    @include media-breakpoint-up(xxl) {
      border-bottom: 1px solid #D9D9D9;
      margin-bottom: 1rem;

      justify-content: flex-end;

      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: auto 1fr auto;
      align-items: flex-end;
      gap: 0;

      padding: 1.125rem 2rem 0 2rem;

      .brand {
        gap: 1rem;
        align-self: flex-start;

        margin-right: 3.25rem;

        p {
          font-weight: 700;
          font-size: 0.9375rem;

          color: $black;

          a {
            color: $black;
            border-bottom-color: $black;
          }
        }
      }

      .bur-search-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding-top: 0.875rem;

        .search-more {
          margin-bottom: 0;
        }
      }

      .login-menu {
        display: flex;
        justify-content: flex-end;

        margin-bottom: 1.5rem;
        max-height: 2.5rem;
      }

      .show-search {
        display: none;
      }

      .show-translate {
        display: none;
      }

      .navbar-collapse {
        grid-row: 2;
        grid-column: 1/4;
        justify-self: flex-end;
      }

      .navbar-nav {
        .nav-item {
          height: 2.5rem;

          &.dropdown {
            &:not(.show) {
              border: 1px solid transparent;
            }

            .dropdown-menu {
              margin-left: -1px; // because of parent's border
              min-width: max(21rem, calc(100% + 2rem));

              z-index: -1;

              .see-all-item {
                padding: 2rem 1.5rem 0.25rem 1.5rem;

                a.btn {
                  color: $primary;
                  border-color: $primary;

                  &:hover {
                    color: $white;
                    background-color: $primary;
                    border-color: $primary;
                  }
                }
              }
            }

            &.show {
              border-left: 1px solid #dfdfdf;
              border-top: 1px solid #dfdfdf;
              border-right: 1px solid #dfdfdf;
              border-bottom: 1px solid $white;
            }
          }

          .dropdown-item {
            &.controls-submenu {
              &:before {
                transform: rotate(90deg);
              }

              &[aria-expanded="true"] {
                color: $white;
                background-color: $color-bur;

                &:before {
                  transform: rotate(270deg);
                  content: escape-svg($bur-dropdown-chevron-submenu-active);

                  left: 0.375rem;
                }
              }
            }
          }

          .submenu {
            position: absolute !important;
            left: 100%;
            top: calc(0.125rem / -2); // half of parents margin
            bottom: calc(0.125rem / -2); // half of parents margin
            overflow-y: auto;
          }
        }
      }
    }

    @include media-breakpoint-down(xl) {
      .navbar-nav {
        flex-grow: 1;

        .nav-item {
          padding: 0;
          border: none;

          &.dropdown {
            flex-direction: column;

            .dropdown-toggle {
              justify-content: space-between;
              width: 100%;
              text-transform: none;
            }

            .dropdown-menu {
              border: none;
              width: 100%;
              padding: 0 0 0 1rem;

              li {
                display: flex;
                flex-direction: column;

                &.see-all-item {
                  padding: .5rem 0 .5rem 1.5rem;
                  align-items: flex-start;

                  a.btn {
                    font-size: $modal-font-size;
                    line-height: $modal-line-height;
                    font-weight: 500;
                    color: $primary;
                    text-decoration: underline;
                    text-decoration-thickness: 1px;

                    padding: 0;

                    &:focus, &:hover {
                      color: $primary;
                      text-decoration-thickness: 2px;
                    }
                  }
                }
              }
            }
          }

          .nav-link {
            padding: .75rem 1rem;
            border-bottom: 1px solid $modal-border-color;
          }

          .nav-link, .dropdown-item {
            font-size: $modal-font-size;
            line-height: $modal-line-height;
            color: $modal-text-color;

            &:hover, &:focus {
              background-color: #e9ecef;
            }
          }

          .dropdown-item {
            white-space: normal;

            border-bottom: 1px solid $modal-border-color;

            &.active, &:active {
              color: $modal-text-color;
              background-color: $white;
            }

            &.controls-submenu {
              &:before {
                margin-right: 1rem;
                transform: rotate(0deg);
              }

              &[aria-expanded="true"] {
                color: $modal-text-color;
                background-color: $white;

                &:before {
                  transform: rotate(180deg);
                  content: escape-svg($bur-dropdown-chevron-active);
                  left: 0;
                  top: -2px;
                }
              }
            }
          }
        }
      }

      .brand {
        gap: .375rem;

        .navbar-brand {
          img {
            height: 2rem;
            width: auto;
          }
        }
      }

      .bur-search-form {
        display: none;
      }
      .login-menu {
        display: none;
      }
    }
  }

  .navbar-brand {
    display: block;
    padding: 0;
  }

  .navbar-nav {
    position: relative;
    z-index: 1;

    .nav-item {
      padding: 0 .875rem;

      display: flex;
      justify-content: center;
      align-items: center;

      &.dropdown {
        > .dropdown-toggle {
          display: inline-flex;
          flex-direction: row-reverse;
          gap: .5rem;

          &:after {
            display: inline-block;
            border: none !important;
          }

          &:before {
            line-height: 1;
            content: escape-svg($bur-dropdown-chevron);
            transform: rotate(180deg);
            top: -5px;
            transition-property: transform, left, top;
            transition-duration: .25s, .25s, .25s;
            position: relative;
          }

          &:hover, &:focus-visible {
            &:before {
              content: escape-svg($bur-dropdown-chevron-active);
            }
          }
        }

        .dropdown-menu {
          margin-top: 0;
        }

        &.show {
          > .dropdown-toggle {
            &:before {
              content: escape-svg($bur-dropdown-chevron-active);
              transform: rotate(0deg);
              position: relative;
              top: 0;
            }
          }
        }
      }

      .nav-link {
        padding: 0;

        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: .9375rem;
        line-height: 1;
      }

      .dropdown-item {
        &.controls-submenu {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          gap: 0.5rem;

          &:before {
            content: escape-svg($bur-dropdown-chevron);

            position: relative;
            left: 0;
            top: 0;

            transition-property: transform, left, top;
            transition-duration: .25s, .25s, .25s;
          }

          &[aria-expanded="true"] {
            &:before {
            }
          }
        }
      }

      .submenu {
        margin: 0;
        border-left: 0.25rem solid $color-bur;
        min-width: 100%;
      }
    }
  }

  .nav-link {
    font-size: 15px;
  }

  .brand {
    margin-right: auto;

    > p {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 0.5rem;
      line-height: 1.5;

      color: #626769;

      margin: 0;

      a {
        color: #626769;
        border-bottom: 1px solid #626769;
      }
    }

    .navbar-brand {
      img {

      }
    }
  }

  .navbar-toggler {
    order: initial !important;
    border: none;
    padding: .5rem;
  }


  .bur-search-form {
    .list-group {
      display: inline-flex;
      border-radius: 5px;
      margin-bottom: 4px;
      border-style: solid;
      border-width: 1px;

      .list-group-item {
        background: white;
        font-size: 14px;
        padding: 0;
        border: 0;

        &.active {
          background: #b61b29;
        }

        a {
          display: block;
          padding: 0.25rem 1rem;

          &:hover {
            background: #b61b29;
            text-decoration: none;
            color: white;
          }
        }


      }

      .list-group-item a {
        color: #4A4A4A;
      }

      .list-group-item:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        a {
          &:hover {
            background: #b61b29;
            text-decoration: none;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            color: white;
          }
        }
      }

      .list-group-item:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        a {
          &:hover {
            background: #b61b29;
            text-decoration: none;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            color: white;
          }
        }
      }
    }

    .list-group-item.active a {
      color: white;

    }

    .list-group-item + .list-group-item.active {
      margin-top: 0;
      border-top-width: 0;
    }
  }

  .search-more {
    font-size: 14px;

    > a {
      border-bottom: 1px solid #000;
    }
  }

  .bur-search {
    border-width: 1px;
    border-color: #4A4A4A;
    border-radius: 5px;
    border-style: solid;
    background: #fff;
    padding: 8px;
    min-width: 520px;

    .search-input {
      -webkit-appearance: none;
      border: none;

    }

    .search-submit {
      background: #fff;
      border: none;
    }
  }

  .login-menu {
    p {
      margin: 0;
    }
  }

  .show-search {
    padding: .5rem;
  }

  .show-translate {
    padding: .5rem;
  }

  #burMenuMobileModal {
    z-index: 7779;
    overflow-y: scroll;

    background-color: $white;

    .modal-dialog {
      margin: 0;
      height: 100%;
      min-width: 100%;

      .modal-content {
        border: none;
        min-height: 100%;
      }
    }

    .modal-header {
      border: none;
      padding: .5rem .75rem .5rem 1rem;

      .close {
        padding: .5rem;
        margin: 0;
        margin-left: auto;
        display: flex;
      }
    }

    .modal-body {
      padding: .75rem .75rem .75rem 1rem;

      display: flex;
      flex-direction: column;
      gap: 3rem;

      .navbar {
        padding: 0;
        margin: 0;
        border: none;
        justify-content: space-around;
        gap: 0;
      }

      .login-menu {
        margin-top: auto;
        max-height: none;

        p {
          display: flex;
          align-items: center;
          gap: 1ch;
        }

        p, a {
          font-size: $modal-font-size;
          line-height: $modal-line-height;
          color: $modal-text-color;
        }

        a {
          text-decoration: underline;

          &:focus-visible, &:hover {
            font-weight: bolder;
            text-decoration-thickness: 2px;
          }
        }

        .btn-login {
          display: inline-flex;
          align-items: center;
          gap: 0.25rem;
          font-weight: 500;

          img {
          }
        }

        .btn-register {
          font-weight: 500;
        }
      }
    }
  }
  footer {
    .container {
      max-width: 1570px;
    }
  }
}

footer {
  .container {
    //max-width: 1570px;

    h2 {
      font-size: 18px;

      @include media-breakpoint-up(lg) {
        font-size: 47px;
      }

    }

  }

  #kontakt {
    .container-fluid {
      @include media-breakpoint-up(lg) {
        background-image: url("../../../img/bur-kontakt-map.png");
        height: 600px;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
      }
    }
  }

  .bur-footer-list {
    color: red;

    li {
      color: white;
      padding: 0.8rem 0;

      span {
        border-bottom: 2px solid white;
      }


      a {
        color: white;
        text-decoration-line: underline;
      }
    }
  }

}

#bur-flags {
  p {
    color: #626769;
    font-size: 12px;

    @include media-breakpoint-up(lg) {
      font-size: 17px;
    }
  }
}


/** bur-2023 end **/
