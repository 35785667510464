form.early_warning_form {
  .row {
    flex-direction: column;
    margin: 0;
  }

  .form-inline {
    display: block;

    .form-group {
      margin-bottom: 1rem;

      .form-control {
        width: 100%;
        border: none;
        border-bottom: 1px solid #e0e1e1;
        padding-left: 0;
        margin-bottom: 0;

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding-left: 0;
        }
      }

      .control-label {
        display: block;
        margin-right: 1rem;
      }

      .nonform-control {
        .field-calendar {
          .input-group {
            display: flex;
            flex-grow: 1;
            flex-wrap: nowrap;
          }
        }

        input[type=checkbox] {
          // margin-bottom: 1.5rem;
        }
      }

      &.other-form-group {
        > .control-label {
          color: #757575;
        }
      }
    }
  }

  .form-group-actions {
    padding-top: 2rem;
    text-align: center;

    .btn {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  span[data-toggle="tooltip"] {
    display: inline !important;
  }

  #bfElemWrap1077,
  #bfElemWrap1083 {
    .form-group {
      direction: rtl;
      float: left;

      label {
        margin-right: 0;
        margin-left: 0.75rem;
      }
    }

    &:after {
      display: block;
      clear: both;
      content: "";
    }
  }
}
