.bfFormDiv {
  form {

    &.contact-parp-form {
      @extend .bfQuickMode;
    }

    label i.fas-asterisk, label i.fa-asterisk {
      font-size: 6px;
      color: $red;
      vertical-align: top;
    }

    &.bfQuickMode {
      .nonform-control {
        width: auto;
        text-align: left;

        .btn {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        .radio-inline {
          text-align: left;
        }

        label {
          margin: 5px 0;
        }
      }

      .form-group {
        .control-label, .form-label {
          width: auto !important;
          min-width: 110px !important;
          display: inline-block !important;
        }
      }

      .btn {
        font-size: 1rem !important;
      }
    }

    .form-select {
      @extend .form-control;
    }

    .form-control, .form-select {
      border-top: none;
      border-right: none;
      border-left: none;
      padding-left: 0;
      margin-bottom: 1rem;
      color: #757575;

      &::placeholder {
        color: #757575;
        font-size: 1rem;
      }
    }

    .bfCustomSubmitButton {
      text-align: center;
    }

    .row {
      flex-direction: column;
      margin: 0;
    }

    .form-inline {
      display: block;

      label {
        display: block;
      }
    }

    &:not(.form-show-legend) {
      fieldset {
        legend {
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          border: 0;
        }
      }
    }
  }
}

.bfSectionDescription {
  width: 100%;
}

.was-validated {
  :valid {
    ~ .feedback {
      .valid-feedback {
        display: block;
      }
    }
  }

  :invalid {
    ~ .feedback {
      .invalid-feedback {
        display: block;
      }
    }
  }
}
