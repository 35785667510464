#public-orders {
  .public-orders-filters {
    label.btn {
      &.active {
        .vector-icon {
          &.vector-icon-grayed-out {
            display: none;
          }
        }
      }

      &:not(.active) {
        .vector-icon {
          &:not(.vector-icon-grayed-out) {
            display: none;
          }
        }
      }

      &.focus,
      &:focus {
        outline: $input-btn-focus-color auto 5px;
      }
    }
  }
}
