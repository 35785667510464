$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6E6E6E;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0c7492;
$indigo: #6610f2;
$purple: #584991;
$pink: #e83e8c;
$red: #b51a29;
$orange: #e16103;
$yellow: #eba00a;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $red;
$secondary: $purple;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: #9c9d9f;
$dark: #565b5d;

$lighterGrey: #f7f7f7;
$darkerLightGrey: #f5f5f5;
$lightGrey: #f2f2f2;
$darkGrey: #ececec;

$poir: #00a097;
$popw: #4b4894;
$power: #dc651e;

$theme-colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "grey-100": $gray-100,
        "grey-200": $gray-200,
        "grey-300": $gray-300,
        "grey-400": $gray-400,
        "grey-500": $gray-500,
        "grey-600": $gray-600,
        "grey-700": $gray-700,
        "grey-800": $gray-800,
        "grey-900": $gray-900,
        "black": $black,
        "lighterGrey": $lighterGrey,
        "darkerLightGrey": $darkerLightGrey,
        "lightGrey": $lightGrey,
        "darkGrey": $darkGrey,
        "lighter-grey": $lighterGrey,
        "darker-light-grey": $darkerLightGrey,
        "light-grey": $lightGrey,
        "dark-grey": $darkGrey,
        "spotkania": #fbd998,
        "cookies": #d5e5ed,
        "poir": $poir,
        "popw": $popw,
        "power": $power,
);

$focus-color: #6ba2f9;

// Top banners
$top_banner_height: 70px;

// Variables
:root {
  --new-color: #{$white};
  --new-color-bg: #{$red};
  --new-color--hover: #{$red};
  --new-color-bg--hover: #{$white};

  --banner-height: #{$top_banner_height};
}

// Options
$enable-rounded: false;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
        20: 20%,
        40: 40%,
        80: 80%,
);

// Body
$body-color: $black;

// Grid containers
$container-max-widths: (
        xl: 1176px
);

// Grid columns
$grid-gutter-width: 20px;

// Components
$border-thick-width: 4px;
$border-radius: 0.5rem;

// Buttons + Forms
$input-btn-padding-y: .45rem;

// Tooltips
$tooltip-color: $black;
$tooltip-bg: $gray-200;
$tooltip-opacity: 1;
$tooltip-padding-y: .5rem;
$tooltip-padding-x: 1rem;

// Custom forms
$custom-control-gutter: 0;
$custom-control-indicator-focus-box-shadow: 0;

$input-btn-focus-color: $focus-color;
//$input-focus-bg:                        $input-bg !default;
//$input-focus-border-color:              lighten($component-active-bg, 25%) !default;
//$input-focus-color:                     red;
//$input-focus-width:                     10px;
//$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

//modal
$modal-backdrop-opacity: 0.8;

// breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1350px
);

//:root {
//  @each $breakpoint, $max-width in $grid-breakpoints {
//    --breakpoint-#{$breakpoint}: #{$max-width};
//  }
//}

$infobox-icon: url('data:image/svg+xml,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8.5" r="8" fill="#{$gray-400}"/><path d="M7.15039 3.71826C7.15039 3.45459 7.23584 3.23975 7.40674 3.07373C7.57764 2.90283 7.79736 2.81738 8.06592 2.81738C8.354 2.81738 8.59326 2.90771 8.78369 3.08838C8.97412 3.26416 9.06934 3.46436 9.06934 3.68896C9.06934 3.85498 9.03027 4.021 8.95215 4.18701C8.87891 4.35303 8.75928 4.4873 8.59326 4.58984C8.43213 4.6875 8.26611 4.73633 8.09521 4.73633C7.86084 4.73633 7.64355 4.63623 7.44336 4.43604C7.24805 4.23584 7.15039 3.99658 7.15039 3.71826ZM6.125 12.4561L6.11035 11.98L6.93799 11.8701C7.06006 11.8359 7.14307 11.7871 7.18701 11.7236C7.23584 11.6553 7.26025 11.5063 7.26025 11.2769V11.1963C7.26025 11.1377 7.2627 11.0327 7.26758 10.8813C7.27734 10.7251 7.28223 10.5884 7.28223 10.4712C7.28223 10.3784 7.2749 10.2002 7.26025 9.93652L7.26758 9.57031L7.26025 9.22607L7.2749 8.03223C7.2749 7.88086 7.26025 7.72461 7.23096 7.56348C7.17725 7.49023 7.07959 7.42676 6.93799 7.37305C6.79639 7.31934 6.5498 7.28027 6.19824 7.25586V6.8457C6.70117 6.81641 7.14307 6.71143 7.52393 6.53076C7.78271 6.41357 8.06348 6.20605 8.36621 5.9082H8.85693C8.87646 6.51367 8.88623 7.2998 8.88623 8.2666L8.83496 11.2549C8.83496 11.46 8.84473 11.6455 8.86426 11.8115C9.01562 11.8652 9.26221 11.9189 9.604 11.9727C9.73584 11.9922 9.84082 12.0166 9.91895 12.0459L9.91162 12.4487C9.77979 12.4536 9.68213 12.4561 9.61865 12.4561C9.54541 12.4561 9.43311 12.4512 9.28174 12.4414C8.78369 12.417 8.11719 12.4048 7.28223 12.4048C7.04297 12.4048 6.81104 12.4146 6.58643 12.4341C6.36182 12.4487 6.20801 12.4561 6.125 12.4561Z" fill="#{$black}"/></svg>');
$active-filter-icon: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.369 0.234882C1.05583 -0.078294 0.54806 -0.078294 0.234886 0.234882C-0.0782955 0.548058 -0.0782955 1.05583 0.234886 1.369L4.86589 6.00002L0.234935 10.631C-0.0782473 10.9442 -0.0782473 11.452 0.234935 11.7651C0.548109 12.0783 1.05587 12.0783 1.36905 11.7651L6 7.13413L10.631 11.7651C10.9441 12.0783 11.4519 12.0783 11.7651 11.7651C12.0782 11.452 12.0782 10.9442 11.7651 10.631L7.1341 6.00002L11.7651 1.369C12.0783 1.05583 12.0783 0.548058 11.7651 0.234882C11.4519 -0.078294 10.9442 -0.078294 10.631 0.234882L6 4.86591L1.369 0.234882Z" fill="#{$primary}"/></svg> ');
$active-filter-icon-disabled: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.369 0.234882C1.05583 -0.078294 0.54806 -0.078294 0.234886 0.234882C-0.0782955 0.548058 -0.0782955 1.05583 0.234886 1.369L4.86589 6.00002L0.234935 10.631C-0.0782473 10.9442 -0.0782473 11.452 0.234935 11.7651C0.548109 12.0783 1.05587 12.0783 1.36905 11.7651L6 7.13413L10.631 11.7651C10.9441 12.0783 11.4519 12.0783 11.7651 11.7651C12.0782 11.452 12.0782 10.9442 11.7651 10.631L7.1341 6.00002L11.7651 1.369C12.0783 1.05583 12.0783 0.548058 11.7651 0.234882C11.4519 -0.078294 10.9442 -0.078294 10.631 0.234882L6 4.86591L1.369 0.234882Z" fill="#{$lightGrey}"/></svg> ');

//region BUR
$color-bur: #b51a29;

$bur-dropdown-chevron: url('data:image/svg+xml, <svg width="16" height="16" fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <path d="m12.665 10.743c0.4106 0.3694 1.0429 0.336 1.4123-0.0746 0.3694-0.4105 0.336-1.0428-0.0746-1.4122l-1.3377 1.4868zm-4.6661-5.5434 0.66885-0.7434c-0.38027-0.34213-0.95743-0.34213-1.3377 0l0.66885 0.7434zm-6.0038 4.0566c-0.41057 0.3694-0.44395 1.0017-0.07455 1.4122 0.36939 0.4106 1.0017 0.444 1.4122 0.0746l-1.3377-1.4868zm12.008 0-5.335-4.8-1.3377 1.4868 5.335 4.8 1.3377-1.4868zm-6.6726-4.8-2.6675 2.4 1.3377 1.4868 2.6675-2.4-1.3377-1.4868zm-2.6675 2.4-2.6675 2.4 1.3377 1.4868 2.6675-2.4-1.3377-1.4868z" fill="#{$black}"/></svg>');
$bur-dropdown-chevron-active: url('data:image/svg+xml, <svg width="16" height="16" fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <path d="m12.665 10.743c0.4106 0.3694 1.0429 0.336 1.4123-0.0746 0.3694-0.4105 0.336-1.0428-0.0746-1.4122l-1.3377 1.4868zm-4.6661-5.5434 0.66885-0.7434c-0.38027-0.34213-0.95743-0.34213-1.3377 0l0.66885 0.7434zm-6.0038 4.0566c-0.41057 0.3694-0.44395 1.0017-0.07455 1.4122 0.36939 0.4106 1.0017 0.444 1.4122 0.0746l-1.3377-1.4868zm12.008 0-5.335-4.8-1.3377 1.4868 5.335 4.8 1.3377-1.4868zm-6.6726-4.8-2.6675 2.4 1.3377 1.4868 2.6675-2.4-1.3377-1.4868zm-2.6675 2.4-2.6675 2.4 1.3377 1.4868 2.6675-2.4-1.3377-1.4868z" fill="#{$color-bur}"/></svg>');
$bur-dropdown-chevron-submenu-active: url('data:image/svg+xml, <svg width="16" height="16" fill="none" version="1.1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <path d="m12.665 10.743c0.4106 0.3694 1.0429 0.336 1.4123-0.0746 0.3694-0.4105 0.336-1.0428-0.0746-1.4122l-1.3377 1.4868zm-4.6661-5.5434 0.66885-0.7434c-0.38027-0.34213-0.95743-0.34213-1.3377 0l0.66885 0.7434zm-6.0038 4.0566c-0.41057 0.3694-0.44395 1.0017-0.07455 1.4122 0.36939 0.4106 1.0017 0.444 1.4122 0.0746l-1.3377-1.4868zm12.008 0-5.335-4.8-1.3377 1.4868 5.335 4.8 1.3377-1.4868zm-6.6726-4.8-2.6675 2.4 1.3377 1.4868 2.6675-2.4-1.3377-1.4868zm-2.6675 2.4-2.6675 2.4 1.3377 1.4868 2.6675-2.4-1.3377-1.4868z" fill="#{$white}"/></svg>');
//endregion BUR

@import "~bootstrap/scss/bootstrap";
@import "partials/bootstrap-hacks";
@import "~bootstrap-select/sass/bootstrap-select";
@import "~tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4";
@import "~animate.css/animate.css";

@import "partials/functions";
@import "partials/general";
@import "partials/select-css";
@import "partials/main";
@import "partials/animation";
@import "partials/badge";
@import "partials/custom_forms";
@import "partials/custom_forms_responsive";
@import "partials/early_warning_form";
@import "partials/fancybox";
@import "partials/icons";
@import "partials/text";
@import "partials/sticky";
@import "partials/sticky_responsive";
@import "partials/tempusdominus-bootstrap-4";
@import "partials/contact_parp_form";
@import "partials/infoline_form";
@import "partials/event-list";
@import "partials/event-list_responsive";
@import "partials/event-detail";
@import "partials/event-detail_responsive";
@import "partials/funding";
@import "partials/funding_responsive";
@import "partials/grant";
@import "partials/grant_responsive";
@import "partials/public_orders";
@import "partials/publications";
@import "partials/search";
@import "partials/schedule";
@import "partials/navbar-footer";
@import "partials/forms";
@import "partials/anchor";
@import "partials/top_bar";
@import "partials/responsive";
@import "partials/ie11";

// Widget
@import "widget/mCustomScrollbar";
@import "widget/typeahead";
@import "widget/main";
@import "widget/custom";
@import "widget/js";
@import "widget/parp";
