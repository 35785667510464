.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.decoration-none {
  text-decoration: none !important;
}

.bg-inherit {
  background: inherit;
}

.rotated {
  transform: rotate(-180deg);
}

.text-underline {
  text-decoration: underline;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.style-type-none {
  list-style-type: none;
}

.border-bottom-radius {
  border-radius: 0 0 5px 5px;
}

img.filter-grayscale {
  filter: grayscale(100%);
}

a[data-toggle="collapse"] {
  &.collapsed {
    .collapse-arrow {
      transform: rotate(180deg);
    }
  }
}

.rounded-bottom {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

// Border
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .border#{$infix} {
      border: $border-width solid $border-color !important;
    }
    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    @each $color, $value in $theme-colors {
      .border#{$infix}-#{$color} {
        border-color: $value !important;
      }
    }
    @each $color, $value in $theme-colors {
      .border#{$infix}-top-#{$color} {
        border-top-color: $value !important;
      }
      .border#{$infix}-right-#{$color} {
        border-right-color: $value !important;
      }
      .border#{$infix}-bottom-#{$color} {
        border-bottom-color: $value !important;
      }
      .border#{$infix}-left-#{$color} {
        border-left-color: $value !important;
      }
    }

    .border#{$infix}-thick {
      border-width: $border-thick-width !important;
    }
    .border#{$infix}-top-thick {
      border-top-width: $border-thick-width !important;
    }
    .border#{$infix}-right-thick {
      border-right-width: $border-thick-width !important;
    }
    .border#{$infix}-bottom-thick {
      border-bottom-width: $border-thick-width !important;
    }
    .border#{$infix}-left-thick {
      border-left-width: $border-thick-width !important;
    }

    .border#{$infix}-0 {
      border: 0 !important;
    }
    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }
  }
}

// .border-white {
//     border-color: $white !important;
// }

// Breadcrumb
@each $color, $value in $theme-colors {
  .breadcrumb-#{$color} {
    .breadcrumb-item {
      + .breadcrumb-item {
        &::before {
          color: $value;
        }
      }
    }
  }
}

.breadcrumb-item.active {
  color: black;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow: hidden !important;
}

.overflow-y-hidden {
  overflow: hidden !important;
}

.h-bar {
  height: 2px;
  margin: 1rem 0 0 0;
  background-color: $white;
  width: 66px;
  max-width: 100%;
}

#cookies {
  z-index: 3030;
}

.border-radius-0 {
  border-radius: 0 !important;
}

