.anchor {
  display: block;
  height: 160px; /*same height as header*/
  margin-top: -160px; /*same height as header*/
  visibility: hidden;
}

.anchor-sticky {
  display: block;
  height: 210px; /*same height as header*/
  margin-top: -210px; /*same height as header*/
  visibility: hidden;

  &.akademia {
    height: 240px;
  }
}

body.has-top-bar {
  .anchor {
    height: 250px;
    margin-top: -250px;
  }

  .anchor-sticky {
    height: 300px;
    margin-top: -300px;
  }
}
