#cookies {
  z-index: 20000;
}

#open-widget-container {
  width: 290px;
  height: 78px;
  display: block;
  border: none;
  background: transparent;
  margin: 3px;
}

.faq-widget {
  right: 5px !important;
  bottom: 0 !important;
  padding: 0 0 20px 64px !important;
  max-height: 100% !important;
  overflow: hidden;

  .head {
    display: none;
  }
}

.faq-widget-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 0;
  //right: 10px;
  //bottom: 0;
  //position: relative;

  a {
    text-decoration: none !important;
    color: #000;
  }
}

/* modyfikacje widgetu */
#chat_invite {
  display: flex;
  height: auto;
  width: 190px;
  bottom: 35px;
  right: 90px;
  background-color: #ffffff;
  border: 1px solid #65696a;
  color: #5b6063;
  margin-right: 10px;
  padding: 15px;
  position: absolute;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0 15px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: linear-gradient(90deg, rgba(0, 116, 154, 1) 26%, rgba(182, 25, 40, 1) 57%);
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    transform: skew(1.5deg, 1.5deg);
  }
}

#chat_open {
  background-image: url(../../../img/widget/FAQ-ico.png);
  background-size: contain;
  border-radius: 50%;
  height: 76px;
  width: 76px;
  position: absolute;
  right: 5px;
  bottom: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  border: 1px solid #626669;
}

#faq-widget-close {
  border: none;
  background-color: transparent;

  //&:focus {
  //  outline: 1px solid #fff;
  //}
}

#close_widget {
  display: none;
}

.head {
  display: none;
}

.message a {
  color: #b51a29;
  text-decoration: none;
}

.message a:hover {
  color: #000;
  text-decoration: underline;
}

.faq-widget-links a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.faq-widget-links a:hover {
  text-decoration: underline;
  color: #000;
}

.horizontal-buttons {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.horizontal-buttons__item {
  display: inline-block;
  vertical-align: middle;
  padding: 0 20px 0 0;
}

.horizontal-buttons__item:last-child {
  padding-right: 0;
}

.typeahead__container button:hover {
  background: none;
}

.faq-widget-chat-message {
  .message {
    margin: 20px 10px 20px 30px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.faq-widget-open {
  box-shadow: 1px 4px 34px rgba(0, 0, 0, 0.2) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  margin-bottom: 46px !important;
  right: 15px !important;
}

@media (max-width: 1400px) {
  .faq-widget-open {
    margin-bottom: 26px !important;
  }
}

@media (max-width: 1100px) {
  .faq-widget-open {
    margin-bottom: 0 !important;
  }
}

.faq-widget-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.faq-widget-header .faq-window-icon-title__icon {
  width: 75px;
  //   position: relative;

  @media (min-width: 400px) {
    // top: 0;
    width: 100px;
  }
}

.faq-widget-chat-message {
  &.msg-bot {
    .message {
      border: 1px solid #626769;
      border-top-left-radius: 1px;

      &:before {
        border-right-color: #626769;
        display: none !important; /** I co to Zdanowskiemu dało? xD */
      }

      &:after {
        display: none !important; /** I co to Zdanowskiemu dało? xD */
      }
    }
  }

  &.msg-user {
    .message {
      border-bottom-right-radius: 1px;

      &:after {
        display: none !important; /** I co to Zdanowskiemu dało? xD */
      }
    }
  }

  .message {
    border-radius: 13px;
  }
}

.chat-finished-section {
  padding: 0 0 20px 0;
}

.chat-question {
  display: block;
  border: 0;
  background: 0;
}

/********* MODIFYING ********/
.line {
  display: block;
}

.spectacular-menu > ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.spectacular-menu > ul > li {
  display: block;
  padding: 0;
  margin: 0;
  position: relative;
}

.spectacular-menu > ul > li:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: #f3f4f5;
}

.spectacular-menu-item {
  cursor: pointer;
  position: relative;
  z-index: 4;
  display: block;
  color: #000;
  text-decoration: none;
  outline-offset: 1px;

  &:active {
    outline: 1px dotted #05a;
  }

  &:hover, &:focus {
    z-index: 5;

    .spectacular-menu-item__wrapper {
      background: #fafafa;
    }
  }
}

.spectacular-menu-item__wrapper {
  padding: 27px 0;
  border-left: 5px solid #00a097;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

@media (max-width: 1600px) {
  .spectacular-menu-item__wrapper {
    padding: 34px 0;

    &.search {
      padding: 16px 0;
    }
  }
}

.spectacular-menu-item__inner {
  padding: 0 20px;
}

@media (max-width: 1600px) {
  .spectacular-menu-item__inner {
    padding: 0 16px;
  }
}

.spectacular-menu-item__container {
  position: relative;
  padding: 0 0 0 60px;
}

@media (max-width: 1600px) {
  .spectacular-menu-item__container {
    padding-left: 50px;
  }
}

.spectacular-menu-item__icon-container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 35px;
  transform: translate3d(0, -50%, 0);
}

@media (max-width: 1600px) {
  .spectacular-menu-item__icon-container {
    width: 28px;
  }
}

.spectacular-menu-item__icon {
  width: 35px;
  height: 35px;
}

@media (max-width: 1600px) {
  .spectacular-menu-item__icon {
    width: 28px;
    height: 28px;
  }
}

.spectacular-menu-item__arrow-container {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  transform: translate3d(0, -50%, 0);
}

@media (max-width: 1600px) {
  .spectacular-menu-item__arrow-container {
    width: 18px;
  }
}

.spectacular-menu-item__arrow {
  width: 16px;
  height: 16px;
}

@media (max-width: 1600px) {
  .spectacular-menu-item__arrow {
    width: 18px;
    height: 18px;
  }
}

.spectacular-menu-item__content {
  font-size: 18px;
}

.spectacular-menu-item--color-01 .spectacular-menu-item__wrapper {
  border-color: #0c7492;
}

.spectacular-menu-item--color-02 .spectacular-menu-item__wrapper {
  border-color: #d66011;
}

.spectacular-menu-item--color-03 .spectacular-menu-item__wrapper {
  border-color: #b61929;
}

.faq-widget__menu-header {
  background: url(../../../img/widget/header-background.jpeg) no-repeat center;
  background-size: cover;
  padding: 40px 30px 40px 200px;
  text-align: center;
}

@media (max-width: 1600px) {
  .faq-widget__menu-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 480px), (max-height: 630px) {
  .faq-widget__menu-header {
    display: none;
  }
}

.faq-widget__menu-title {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
}

.faq-widget-chat-message .head {
  display: none;
}

.chat-container {
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0 60px 0;
  position: relative;
}

.faq-widget__search-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.faq-widget__footer {
  background: #f3f4f5;
  text-align: center;
  padding: 12px 0;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.faq-widget__footer p {
  margin: 0;
  padding: 0;
}

.simple-content-manager {
  position: relative;
}

.simple-content-manager__wrapper {
  position: relative;
}

.simple-content-manager__back {
  cursor: pointer;
}

.simple-content-manager__menu {
  transition: all 0.2s linear;
  position: relative;
  z-index: 3000;
}

.simple-content-manager__menu-item.active {
  background: #f3f4f5;
}

.simple-content-manager__content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 0 0 45px;
}

.simple-content-manager__content-wrapper {
  height: 100%;
  padding: 20px 0 20px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.simple-content-manager__content-item {
  height: 100%;
  position: relative;
}

.simple-content-manager__menu.active {
  width: 45px;
}

.simple-content-manager__menu.active:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #f3f4f5;
}

.simple-content-manager__menu.active .spectacular-menu-item__content-container {
  display: none;
}

.simple-content-manager__menu.active .spectacular-menu-item__arrow-container {
  display: none;
}

.simple-content-manager__menu.active .faq-widget__menu-header {
  display: none;
}

.simple-content-manager__menu.active .spectacular-menu-item__wrapper {
  padding: 48px 0;
  min-height: 35px;
  box-sizing: content-box;
}

@media (max-width: 1600px) {
  .simple-content-manager__menu.active .spectacular-menu-item__wrapper {
    padding: 35px 0;
  }
}

.simple-content-manager__menu.active .spectacular-menu-item__inner {
  padding: 0 6px;
}

.simple-content-manager__menu.active .spectacular-menu-item__icon {
  width: 28px;
  height: 28px;
}

.simple-content-manager__menu.active .spectacular-menu-item__container {
  padding: 0;
}

.simple-content-manager__menu.active .spectacular-menu-item__icon-container {
  position: relative;
  transform: none;
}

.simple-content-manager__content-item {
  display: none;
}

.faq-window-content {
  padding: 0 36px 0 20px;
}

.faq-window-content--narrower {
  margin: 0 10px;
}

.faq-window-simple-title {
  font-size: 23px;
  font-weight: 100;
  margin: 0;
  font-family: "NovelPro", serif;
  padding: 0;

  @media (max-width: 480px) {
    font-size: 17px;
  }
}

.faq-window-icon-title {
  display: flex;
}

.faq-window-icon-title__icon {
  display: block;
}

.faq-window-subsection-title {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  width: 90%;
  margin: 0;
  padding: 0;
}

.faq-window-subsection-title--shy {
  font-weight: 400;
}

.icon-label {
  color: #b61929;
  font-size: 16px;
}

.icon-label a {
  color: #b61929;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none;
}

.icon-label a:hover {
  color: #000;
  text-decoration: underline;
}

.icon-label__container {
  position: relative;
  padding: 0 0 0 38px;
}

.icon-label__container:after {
  content: "";
  display: block;
  clear: both;
}

.icon-label__icon-container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 28px;
  transform: translate3d(0, -50%, 0);
}

.icon-label__icon {
  display: block;
}

.plain-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.plain-list > li {
  margin: 0;
  padding: 0;
  display: block;
}

.subsections {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.subsections > li {
  display: block;
  padding: 15px 0;
  position: relative;
}

.subsections > li:first-child {
  padding-top: 0;
}

.subsections > li:last-child {
  padding-bottom: 0;
}

.subsections > li:last-child:after {
  display: none;
}

.subsections > li:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e2e4e5;
}

.subsection__header {
  padding: 0;
}

.subsection__row {
  padding: 10px 0;
}

.subsection__row:first-child {
  padding-top: 0;
}

.subsection__row:last-child {
  padding-bottom: 0;
}

.contact-info__phone-container {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 480px) {
  .contact-info__phone-container {
    display: block;
  }
}

.contact-info__content-container {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 40px;
  font-size: 14px;
  line-height: 22px;
}

@media (max-width: 1600px) {
  .contact-info__content-container {
    padding-left: 20px;
  }
}

@media (max-width: 480px) {
  .contact-info__content-container {
    display: block;
    padding: 20px 0 0 0;
  }
}

.text {
  font-size: 14px;
  line-height: 22px;
}

.text p {
  margin: 0;
}

.text a {
  color: #b61929;
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}

.custom-button {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  background: none;
  border: 0;
  color: #000;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

@media (max-width: 1600px) {
  .custom-button {
    font-size: 14px;
  }
}

.custom-button__wrapper {
  padding: 8px 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.custom-button__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b61929;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-button:hover {
  color: #fff;
}

.custom-button:hover .custom-button__wrapper {
  background: #b61929;
}

.simple-button {
  border: 1px solid #b61929;
  padding: 5px 20px;
  background: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.simple-button:hover,
.simple-button:focus {
  color: #fff;
  background: #b61929;
}

.faq-widget-coms form button {
  background: none;
}

/***** FORMS *****/
.simple-form__wrapper {
  border: 0;
  display: block;
}

.simple-form__field-container {
  margin-bottom: 0.5rem;
  padding: 2px 0;
  display: flex;

  @media (max-width: 479.98px) {
    display: block;
  }

  .form-field {
    display: block;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 100%;

    &:not(:only-child) {
      flex-basis: 50%;

      &[for="callback-form-day"] {
        flex-basis: auto;
      }

      &[for="callback-form-timeslot"] {
        flex-basis: auto;
      }

      &:not(:first-child) {
        padding-left: 4px;
      }

      &:not(:last-child) {
        padding-right: 4px;
      }

      @media (max-width: 479.98px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__header {
      padding: 0 0 5px 0;
    }

    &__title {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      padding: 0;

      @media (max-width: 1600px) {
        font-size: 13px;
      }
    }

    &__core {
      background: #f3f4f5;
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 8px 12px;
      font-size: 14px;
      border: 1px solid transparent;
      transition: all 0.2s linear;
      scrollbar-width: thin;

      @media (max-width: 1600px) {
        padding: 5px 10px;
        font-size: 13px;
      }

      &:focus {
        border-color: #000;
      }
    }
  }

  .form-field.error .form-field__core {
    border-color: #c00;
  }

  .form-field--required .form-field__title:after {
    content: "*";
    color: #b61929;
    padding: 0 0 0 2px;
  }
}

.simple-form__button-container {
  padding: 20px 0 0 0;
  text-align: right;
}

@media (max-width: 1600px) {
  .simple-form__button-container {
    padding-top: 15px;
  }
}

.simple-form__info-container {
  display: none;
  padding: 10px 0 0 0;
}

.livechat {
  height: 100%;
}

.livechat__offline-info {
  padding: 0 0 20px 0;
}

.livechat__pre-survey {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
}

.livechat__message-box {
  padding: 0 10px 0 0;
}

.livechat__chat-info {
  color: #c4c4c4;
  text-align: center;
  padding: 20px 0;
  font-weight: 700;
  font-size: 14px;
}

.livechat__chat-button {
  text-align: center;
}

.livechat__offline-info-container {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
}

#lc {
  position: relative;
  height: 100%;
}

.chat-note {
  border: 1px solid #f3f4f5;
  padding: 10px 15px;
}

.chat-note--success {
  border-color: #2cab00;
}

.form__info-container {
  padding: 10px 0 0 0;
}

.form__info {
  text-align: center;
  font-size: 14px;
}

@media (min-width: 768px) {
  .form__info {
    text-align: left;
  }
}

.single-checkbox__inner {
  padding: 0 0 0 28px;
  position: relative;
  text-align: left;
}

.single-checkbox__description {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #000;
  padding: 0;
}

@media (min-width: 1800px) {
  .single-checkbox__description {
    font-size: 13px;
    line-height: 19px;
  }
}

.single-checkbox__description p {
  padding: 3px 0;
  margin: 0;
}

.single-checkbox__description p:first-child {
  padding-top: 0;
}

.single-checkbox__description p:last-child {
  padding-bottom: 0;
}

.single-checkbox__field-container {
  position: absolute;
  top: 2px;
  left: 0;
}

.single-checkbox__link {
  color: #b61929;
  text-decoration: underline;
}

.choice-field {
  display: block;
  position: relative;
}

.choice-field__wrapper {
  padding: 2px;
  border: 1px solid #777;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
  display: block;
}

.choice-field__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid #0c7492;
  width: 100%;
  height: 100%;
  padding: 3px;
  top: -4px;
  left: -4px;
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.choice-field__inner {
  width: 10px;
  height: 10px;
  background: #b61929;
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.choice-field input {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.choice-field input:checked ~ .choice-field__wrapper .choice-field__inner {
  opacity: 1;
}

.choice-field input:focus ~ .choice-field__wrapper {
  background: #e6e7e8;
  border-color: #000;
}

.choice-field input:focus ~ .choice-field__wrapper:before {
  opacity: 1;
}

.choice-field.error .choice-field__wrapper {
  border-color: #c00;
}

.error {
  color: #c00;
}

.tooltip-item {
  position: relative;
}

.tooltip-item__tooltip-container {
  position: absolute;
  width: 400px;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  display: none;
  max-width: 100%;
  padding: 0 0 10px 0;
}

.tooltip-item__button {
  cursor: pointer;
}

.local-tooltip {
  background: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  -webkit-box-shadow: 0 0.6rem 3rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0.6rem 3rem rgba(0, 0, 0, 0.25);
  box-shadow: 0 0.6rem 3rem rgba(0, 0, 0, 0.25);
  background: #f7f7f7;
}

.local-tooltip__wrapper {
  padding: 30px 16px 8px 16px;
}

.local-tooltip__wrapper a {
  color: #b61929;
  text-decoration: none;
}

.local-tooltip__wrapper a:hover {
  text-decoration: underline;
}

.local-tooltip__close-container {
  position: absolute;
  top: 6px;
  right: 6px;
}

.local-tooltip__close {
  cursor: pointer;
}

#send-button {
  border: 0;
}
