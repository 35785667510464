#searchForm {
  .form-control:focus {
    box-shadow: none;
    border-color: $input-border-color;
  }

  #closeSearch:focus,
  button[name="Search"] {
    box-shadow: none;
  }

  input[name="searchword"] {
    height: auto;
  }

  .ordering-box {
    .dropdown {
      button {
        padding: 0.45rem 0.75rem 0.1rem 0.75rem !important;
      }
    }
  }
}

#container-search {
  @include media-breakpoint-down(sm) {
    .panel-search {
      max-width: 100vw;

      svg.fa-search {
        flex-shrink: 0;
        margin: 0 0 0 8px;
      }

      form {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0 16px;

        label {
          width: 100%;
          margin: 0 !important;

          .form-search {
            width: 100%;
            margin: 0 !important;
          }
        }
      }

      #closeSearch {
        flex-shrink: 0;
      }
    }
  }
}
