@use "sass:map";

body {
    font-family: "Calibri";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.d-list-item {
    display: list-item !important;
}

.d-lg-list-item {
    @include media-breakpoint-up(lg) {
        display: list-item !important;
    }
}

.d-xl-list-item {
    @include media-breakpoint-up(xl) {
        display: list-item !important;
    }
}

.d-xxl-list-item {
    @include media-breakpoint-up(xxl) {
        display: list-item !important;
    }
}

h1 {
    font-family: NovelPro;
    font-weight: 600;
    font-size: 2rem;
}

h2 {
    font-family: NovelPro;
    font-weight: 600;
    font-size: 2.375rem;
}

h2.background,
h3.background {
    position: relative;
    z-index: 1;
    text-align: center;
}

h2.background:before,
h3.background:before {
    border-top: 1px solid #e0e1e1;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
}

h2>span,
h3>span {
    background-color: #fff;
}

.bg-corners {
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    position: absolute;
    z-index: 2;
}

a:hover {
    color: #b51a29;
}

.skip-menu {
    position: fixed !important;
    top: 0;
    z-index: 6000;
    color: #000;
    font-weight: bold;
    font-size: 1.125rem;
    margin-left: 26px !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #b51a29;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.badge-primary {
    color: #fff;
    background-color: #b51a29;
}

.badge {
    font-size: 1rem;
}

#mainPage .jumbotron {
    padding: 0;
    margin: 0;
}

#introText p {
    font-size: 2rem;
    color: #626769;
}

#introText .input-search {
    border-left: 7px solid #b51a29;
    height: 3.5rem;
    font-size: 1.7rem;
    padding: 1rem 1rem 1rem 2rem;
}

#businessStart,
#businessDev,
#businessEnd,
#businessExtra {
    position: relative;

    &:not(.active) {
        &:hover {
            transform: scale(1.03);
            z-index: 1000;
            transition-duration: 700ms;
        }
    }

    >div {
        flex-grow: 1;
    }
}

.tileDev {}

.tileDev:hover {
    background-color: #e16103 !important;
}

.tileStart:hover {
    background-color: #b61928 !important;
}

.tileEnd:hover {
    background-color: #0c7492 !important;
}

.tileStart:hover p,
.tileDev:hover p,
.tileEnd:hover p {
    color: #fff !important;
}

.bg-start {
    background-color: #b61928 !important;
}

.bg-dev {
    background-color: #c45502 !important;
}

.bg-end {
    background-color: #00749a !important;
}

#businessSteps .col-md-4,
#businessSteps .col-md-6,
#businessSteps .col-md-3 {
    padding-left: 0;
    padding-right: 0;
}

#businessSteps .com-md-6.active {
    max-height: 37.5rem;
}

#businessSteps h2 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.125rem;
}

#businessSteps .col-md-3 {
    margin-top: 0.8125rem;
    margin-bottom: 2.5rem;
    padding-left: 0;
    padding-right: 0;
}

// #businessStart.knowledge:not(.active), #businessDev.knowledge:not(.active), #businessEnd.knowledge:not(.active) {
// }

#businessStart.knowledge:not(.active)>div {
    background-color: #490a10 !important;
    z-index: 3;
    position: relative;
}

#businessStart.knowledge:not(.active)>div p {
    // display: none !important;
}

#businessDev.knowledge:not(.active)>div {
    background-color: #5a2701 !important;
    z-index: 3;
    position: relative;
}

#businessDev.knowledge:not(.active)>div p {
    // display: none !important;
}

#businessEnd.knowledge:not(.active)>div {
    background-color: #052e3a !important;
    z-index: 3;
    position: relative;
}

#businessEnd.knowledge:not(.active)>div p {
    // display: none !important;
}

#businessStart.knowledge:before,
#businessDev.knowledge:before,
#businessEnd.knowledge:before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
}

#businessStart.knowledge.active,
#businessDev.knowledge.active,
#businessEnd.knowledge.active {
    position: relative;
    box-shadow: 0 0.6rem 3rem rgba(0, 0, 0, 0.25) !important;
    min-height: 40.625rem;
}

#businessStart.knowledge.active:after {
    content: "";
    height: 3rem;
    width: 3rem;
    background-color: #b61928;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    bottom: -1.5rem;
    left: calc(50% - 1.5rem);
}

#businessDev.knowledge.active:after {
    content: "";
    height: 3rem;
    width: 3rem;
    background-color: #c45502;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    bottom: -1.5rem;
    left: calc(50% - 1.5rem);
}

#businessEnd.knowledge.active:after {
    content: "";
    height: 3rem;
    width: 3rem;
    background-color: #0c7492;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    bottom: -1.5rem;
    left: calc(50% - 1.5rem);
}

#businessStart.knowledge:hover:before,
#businessDev.knowledge:hover:before,
#businessEnd.knowledge:hover:before {
    background-color: rgba(0, 0, 0, 0);
}

#businessStart.knowledge.active:before,
#businessDev.knowledge.active:before,
#businessEnd.knowledge.active:before {
    background-color: rgba(0, 0, 0, 0) !important;
}

#businessStart.knowledge:hover>div {
    background-color: #b61928 !important;
}

#businessStart.knowledge:hover>div p {
    display: block !important;
}

#businessDev.knowledge:hover>div {
    background-color: #c45502 !important;
}

#businessDev.knowledge:hover>div p {
    display: block !important;
}

#businessEnd.knowledge:hover>div {
    background-color: #0c7492 !important;
}

#businessEnd.knowledge:hover>div p {
    display: block !important;
}

#news h6 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.12rem;
}

#news .list-group-item {
    margin-bottom: 0.5rem;
}

#news .borderNews {
    border-bottom: 1px solid #e6f1f4;
}

.borderFeatured {
    border-bottom: 2px solid #b51a29;
}

.list-group-item-action {
    color: #000;
}

#guidesReportsArticles h6 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.125rem;
}

#guidesReportsArticles .bg-icon {
    padding: 0.9375rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

#guidesReportsArticles .icon:hover a {
    color: #b61928 !important;
}

#news .bg-icon {
    padding: 0.625rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
}

#guidesReportsArticles .bg-icon.guides {
    background-color: #b61928;
}

#guidesReportsArticles .icon:hover .bg-icon.guides,
#guidesReportsArticles .icon:hover .bg-icon.reports,
#guidesReportsArticles .icon:hover .bg-icon.research,
#guidesReportsArticles .icon:hover .bg-icon.articles {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
}

#guidesReportsArticles .bg-icon.reports {
    background-color: #0c7492;
}

#guidesReportsArticles .bg-icon.articles {
    background-color: #f7a500;
}

#guidesReportsArticles .bg-icon.research {
    background-color: #00a097;
}

#fundingJumbotron {
    background-image: url("../../../img/finansowanie-bg.jpg");
    background-size: cover;
    background-position: top center;
}

//noinspection CssOverwrittenProperties
//noinspection CssOverwrittenProperties
#fundingPageJumbotron {
    background-image: url("../../../img/finansowanie-bg-small-2022.jpg");
    background-image: image-set(url("../../../img/finansowanie-bg-small-2022.jpg") 1x, url("../../../img/finansowanie-bg-small-2022@2x.jpg") 2x);
    background-image: -webkit-image-set(url("../../../img/finansowanie-bg-small-2022.jpg") 1x, url("../../../img/finansowanie-bg-small-2022@2x.jpg") 2x);
    background-size: cover;
    background-position: top center;

    @include media-breakpoint-up(md) {
        background-image: url("../../../img/finansowanie-bg-2022.jpg");
        background-image: image-set(url("../../../img/finansowanie-bg-2022.jpg") 1x, url("../../../img/finansowanie-bg-2022@2x.jpg") 2x);
        background-image: -webkit-image-set(url("../../../img/finansowanie-bg-2022.jpg") 1x, url("../../../img/finansowanie-bg-2022@2x.jpg") 2x);
        background-size: cover;
        background-position: top center;
    }
}

#stationary-trainings {
    background-image: url("../../../img/stacjonarne-bg.jpg");
    background-size: cover;
    background-position: top center;
}

#fundingJumbotron {
    border-top: 15px solid #a8132b;
}

#fundingJumbotron h4 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.75rem;
}

#fundingJumbotron #deadlines {
    font-size: 1.25rem;
}

#fundingPageJumbotron {
    .row {
        >.col {
            padding: 0;
        }

        &.jumbotron-main-row {
            padding-top: 2.625rem;

            @include media-breakpoint-up(md) {
                padding: 115px 0;
            }

            >div {
                padding: 0 1rem;

                @include media-breakpoint-up(md) {
                    padding: 0;
                }
            }

            .jumbotron-content {
                @include media-breakpoint-up(xl) {
                    max-width: 43.75rem;
                }
            }
        }
    }

    .logo-square {
        width: 3.375rem;
        height: 3.375rem;

        @include media-breakpoint-up(md) {
            width: 5.0625rem;
            height: 5.0625rem;
        }
    }

    h1 {
        font-size: 2.625rem;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 0.5rem;

        @include media-breakpoint-up(md) {
            font-size: 3.625rem;
            margin-top: 0;
        }

        +p {
            font-size: 1rem;
            font-family: "Calibri", sans-serif;
            font-weight: 400;
            text-align: left;
            margin-bottom: 2rem;

            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
                margin-bottom: 0;
            }
        }
    }

    .timetable-link {
        padding: 0.75rem 1rem;

        margin: 0 1rem 1rem auto;

        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }

        img {
            position: relative;
            top: -0.1875rem;
            margin-right: 0.5rem;
        }
    }
}

.eventList .eventCard:hover {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.25);
}

.bg-parp {
    background-image: url("../../../img/bg-parp.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $lighterGrey !important;
}

#footerLinks {
    background-color: #8e0b18;

    ul {
        padding: 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            padding: 0.45rem 0;
        }
    }

    span.border-bottom {
        border-width: 2px !important;
    }

    .more {
        text-transform: uppercase;
    }
}

footer p.address {
    font-size: 0.815rem;
    line-height: 100%;
}

.img-corners {
    border-top-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

#trainings h6 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 1.25rem;
}

#trainings p {
    font-size: 1.25rem;
}

#trainings ul {
    padding: 0;
}

#trainings li {
    background-image: url("../../../img/arrow-right.svg");
    background-size: 20px 1.25rem;
    background-repeat: no-repeat;
    list-style-type: none;
    background-position: 0 0.5rem;
    padding-left: 1.25rem;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
}

#events .eventDate {
    font-family: "Calibri";
    font-weight: 400;
    font-size: 1rem;
}

#events .eventTitle {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.125rem;
}

#events .eventLink {
    font-family: "Calibri", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
}

#events .eventList div:nth-child(3) {
    border: none !important;
}

#lastAdded h6 {
    font-family: "Calibri", sans-serif;
    font-weight: 500;
    font-size: 0.81rem;
    text-transform: uppercase;
}

#contactForm h6 {
    font-family: "NovelPro", sans-serif;
    font-weight: 600;
    font-size: 2.4rem;
}

#contactForm p,
#contactForm legend {
    font-family: "Calibri", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
}

.social-link {
    border: 1px solid black;
    padding: 0.3125rem;
    margin-right: 0.875rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;

    &:hover {
        border: 1px solid #b61928;
    }

    &:hover path {
        fill: #b61928;
    }
}

.social-link-white {
    border: 1px solid white;
    padding: 0.3125rem;
    margin-right: 0.625rem;
    border-top-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;

    &:hover {
        border: 1px solid white;
    }

    &:hover path {
        fill: white;
    }
}

#contactForm label {
    font-family: "Calibri", sans-serif;
    font-weight: 500;
    font-size: 0.81rem;
}

#container-search {
    display: none;
}

.form-search {
    width: 31.875rem;
    border: none;
    border-bottom: 1px solid #90a4ae;
}

.results-search {
    background-color: #fff;
    width: 37.5rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.results-search li {
    list-style-type: none;
}

.panel-search {
    height: 4.25rem;
    background-color: white;
}

#details .goals ul,
.red-diamond-list ul,
ul.red-diamond-list {
    list-style: none;
    padding-left: 0;
}

#details .goals ul li,
.red-diamond-list ul li,
ul.red-diamond-list li {
    margin: 0;
    margin-bottom: 1em;
    padding-left: 2rem;
    position: relative;
}

#details .goals ul,
.red-diamond-list {
    >li {
        &:after {
            content: "";
            height: 0.625rem;
            width: 1.5625rem;
            background-image: url("../../../img/falka-red.svg");
            background-repeat: no-repeat;
            top: 0.65rem;
            left: 0;
            position: absolute;
        }

        ul {
            >li {
                &:before {
                    content: "";
                    height: 0.625rem;
                    width: 1.5625rem;
                    background-image: url("../../../img/falka2.svg");
                    background-repeat: no-repeat;
                    top: 0.65rem;
                    left: 0;
                    position: absolute;
                }
            }

            &.red-diamond-list {
                >li {
                    &:after {
                        content: "";
                        height: 0 !important;
                        width: 0 !important;
                        background-image: none !important;
                        background-repeat: no-repeat !important;
                        position: static !important;
                    }

                    &:before {
                        content: "";
                        height: 0.625rem;
                        width: 1.5625rem;
                        background-image: url("../../../img/falka2.svg");
                        background-repeat: no-repeat;
                        top: 0.65rem;
                        left: 0;
                        position: absolute;
                    }
                }
            }
        }
    }
}

#details .goals ul li>ul li::after {
    content: none;
}

.goal {
    font-family: "Calibri";
    font-size: 1.25rem;
    font-weight: 400;
}

#nav-grant,
#nav-event-details {
    border-bottom: 1px solid #e0e1e1;
    background-color: #fff;
    top: 4.25rem;
    z-index: 2000;
    padding: 0;

    .max-width {
        max-width: map.get($container-max-widths, xl);
        margin: 0 auto;
    }

    li.nav-item {
        padding-bottom: 0;
    }

    .nav-link {
        font-family: "Calibri";
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        text-transform: initial;
        border: 0;
        padding: 0 15px 15px 0.9375rem;

        &.active {
            border-bottom: 2px solid #b61928;
            padding: 0 15px 15px 0.9375rem;
        }
    }
}

#goalMenu {
    background-color: #f7f7f7;
}

#goalMenu ul {
    margin-left: 12.25rem;
}

#goalMenu .nav-link {
    font-family: "Calibri", sans-serif;
    font-weight: 600;
    font-size: 0.88rem;
    text-transform: initial;
}

#goalMenu .start {
    border-bottom: 4px solid #b51a29;
}

#goalMenu .dev {
    border-bottom: 4px solid #eba00a;
}

#goalMenu .finish {
    border-bottom: 4px solid #316a96;
}

.breadcrumb {
    background-color: initial !important;
}

#grantDocuments .card {
    border: none;
}

#grantDocuments .card-header {
    background-color: #fff;
    border: none;
}

#grantDocuments .card .collapse.show,
#results-archive .card .collapse.show {
    background: none;
}

#grantDocuments .card:first-child .card-header.collapsed {
    background-color: #fff;
}

#grantDocuments .card:first-child .card-body {
    border-bottom: none !important;
}

#grantDocuments .card-header.collapsed {
    background-color: white;
}

#grantDocuments .card-header:not(.collapsed) {
    background-color: #f7f7f7;
    border-bottom: 0 !important;
}

#grantDocuments .card:last-child .card-header {
    border-bottom: 1px solid #dee2e6 !important;
}

#grantDocuments .card:last-child .card-header:not(.collapsed) {
    border-bottom: none !important;
}

#grantDates .line {
    border-bottom: 2px solid #565b5d;
}

.card-scroll {
    flex-wrap: nowrap;
    overflow-x: hidden;
}

.card-scroll-container {
    position: relative;
}

.card-scroll-left {
    position: absolute;
    left: 0.3125rem;
    top: 6.25rem;
    z-index: 1;
    color: #b51a29;
    font-size: 2rem;
    display: none;
}

.card-scroll-right {
    position: absolute;
    right: 0.3125rem;
    top: 6.25rem;
    z-index: 1;
    color: #b51a29;
    font-size: 2rem;
}

.grant-scroll-left {
    position: absolute;
    left: 0.3125rem;
    top: 6.25rem;
    z-index: 1;
    color: #b51a29;
    font-size: 2rem;
    display: none;
}

.grant-scroll-right {
    position: absolute;
    right: 0.3125rem;
    top: 6.25rem;
    z-index: 1;
    color: #b51a29;
    font-size: 2rem;
}

#grantDates .card {
    border-radius: .5rem;
}

#grantDates .card .card-body.fill-form {
    border-radius: 0 0 .5rem .5rem;
}

.angle-down {
    margin-top: 0.1875rem;
}

#trainings-cards .card {
    border: none;
}

.poir:hover,
.bg-poir {
    background-color: #00847d !important;
}

.text-poir {
    color: #00847d !important;
}

.power:hover,
.bg-power {
    background-color: #dc651e !important;
}

.text-power {
    color: #dc651e !important;
}

.popw:hover,
.bg-popw {
    background-color: #4b4894 !important;
}

.funduszenorweskie:hover,
.bg-funduszenorweskie {
    background-color: #0033cc !important;
}

.text-popw {
    color: #4b4894 !important;
}

.program-default:hover,
.bg-program-default,
.bg-default,
.default:hover {
    background-color: #b61928 !important;
}

#programy a:hover p.text-black,
#programy a:hover h6.text-black,
#programy a:hover p.text-info,
#programy a:hover li.text-light,
#programy a:hover p.text-light {
    color: #fff !important;
}

#grantFAQ .card-header {
    border: none;
}

#grantFAQ .card {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

#grantFAQ .faq-container>div:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.breadcrumb,
.breadcrumb-back {
    font-size: 0.875rem;
}

#publication .bg-publication-cover {
    background-image: linear-gradient(to right, rgba(182, 25, 40, 1) 0%, rgba(182, 25, 40, 0.9) 10%, rgba(182, 25, 40, 0.85) 50%, rgba(182, 25, 40, 0.9) 90%, rgba(182, 25, 40, 1) 100%),
        url("../../../img/default-cover.png");
    min-height: 34.6875rem;
    background-blend-mode: normal;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-english {
    background-image: linear-gradient(to right, rgba(182, 25, 40, 1) 0%, rgba(182, 25, 40, 0.9) 10%, rgba(182, 25, 40, 0.85) 50%, rgba(182, 25, 40, 0.9) 90%, rgba(182, 25, 40, 1) 100%),
        url("../../../img/bg-english.jpg");
    min-height: 34.6875rem;
    background-blend-mode: normal;
    background-repeat: no-repeat;
    background-size: cover;
}

.publication-description a {
    color: white;
}

.breadcrumb.breadcrumb-white a {
    color: white;
}

.breadcrumb.breadcrumb-white .breadcrumb-item.active {
    color: white;
}

.breadcrumb.breadcrumb-white .breadcrumb-item+.breadcrumb-item::before {
    color: white;
}

#publication .toc {
    border-bottom: 3px solid #757575;
    color: #757575;
}

#author .bg-author-cover {
    background-image: linear-gradient(to right, rgba(12, 116, 146, 1) 0%, rgba(12, 116, 146, 0.9) 10%, rgba(12, 116, 146, 0.85) 50%, rgba(12, 116, 146, 0.9) 90%, rgba(12, 116, 146, 1) 100%),
        url("../../../img/default-cover.png");
    min-height: 34.6875rem;
    background-position: center top;
    background-blend-mode: normal;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-author {
    background-color: rgba(12, 116, 146, 1);
}

.bg-lightGrey50 {
    background: linear-gradient(to bottom, rgba(242, 242, 242, 1) 0%, rgba(242, 242, 242, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

#nav-author {
    border-bottom: 1px solid #e0e1e1;
    background-color: #fff;
    z-index: 2000;
}

#nav-author .nav-link {
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: initial;
    border: 0;
    padding: 0 15px 15px 0.9375rem;
}

#nav-author li.nav-item {
    padding-bottom: 0;
}

#nav-author .nav-link.active {
    border-bottom: 2px solid #b61928;
    padding: 0 15px 15px 0.9375rem;
}

#author-publications img.shadow {
    border: 1px solid #ccc;
    box-shadow: 4px 4px 0px 0px rgba(143, 143, 143, 1) !important;
}

.pagination .page-link {
    color: #565b5d;
    font-weight: 600;
}

.page-item.active .page-link {
    background: none;
    color: #b61928;
}

.pagination img.arrow-left,
.pagination img.arrow-right {
    width: 1.5rem;
    height: 1.5rem;
}

.card.card-publication {
    background-color: initial;
}

.card-publication:hover img {
    opacity: 0.4;
}

.card-publication:hover a {
    color: #b51a29 !important;
}

.width-1176 {
    max-width: map.get($container-max-widths, xl);
}

#lastAdded .bg-report {
    background-color: #00749a;
    background-blend-mode: multiply;
    opacity: 0.8;
    background-position-x: center;
    background-size: cover;
}

#lastAdded .bg-article {
    background-color: #278900;
}

#lastAdded .bg-report:hover,
#lastAdded .bg-guide:hover,
#lastAdded .bg-video:hover,
#lastAdded .bg-dark:hover,
#lastAdded .bg-article:hover,
#lastAdded .bg-misc:hover {
    //transform: scale(1.03);
    //z-index: 1000;
}

#lastAdded .bg-guide {
    background-color: #b61928;
    background-blend-mode: multiply;
    opacity: 0.8;
    background-position-x: center;
    background-size: cover;
}

#lastAdded .bg-video {
    background-color: #222222;
    background-blend-mode: multiply;
    opacity: 0.8;
    background-size: cover;
    background-repeat: no-repeat;
}

.balloon {
    border-left: 1px solid #b51a29;
    border-bottom: 1px solid #b51a29;
}

.balloon:after {
    content: "";
    height: 0.75rem;
    width: 1.5625rem;
    background-image: url("../../../img/arrow-balloon.svg");
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    left: 8%;
    bottom: -0.625rem;
    z-index: 1;
}

.documents-list li {
    list-style-type: none;
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 4rem;
}

.documents-list li.pdf,
.pdf {
    background-image: url("../../../img/pdf.svg");
}

.documents-list li.doc,
.doc {
    background-image: url("../../../img/doc.svg");
}

.documents-list li.mobi,
.mobi {
    background-image: url("../../../img/mobi.svg");
}

.documents-list li.download,
.download {
    background-image: url("../../../img/download.svg");
}

.documents-list li.docx,
.docx {
    background-image: url("../../../img/docx.svg");
}

.documents-list li.xlsx,
.xlsx {
    background-image: url("../../../img/xlsx.svg");
}

.documents-list li.xls,
.xls {
    background-image: url("../../../img/xls.svg");
}

.documents-list li.zip,
.zip {
    background-image: url("../../../img/zip.svg");
}

.documents-list li.ppt,
.ppt {
    background-image: url("../../../img/ppt.svg");
}

.documents-list li.pptx,
.pptx {
    background-image: url("../../../img/pptx.svg");
}

.documents-list li.www,
.www {
    background-image: url("../../../img/www.svg");
}

.documents-list li.csv,
.csv {
    background-image: url("../../../img/csv.svg");
}

.documents-list li.others,
.others {
    background-image: url("../../../img/others.svg");
}

.documents-list li.pdf-small,
.pdf-small {
    background-image: url("../../../img/pdf-small.svg");
}

.documents-list li.doc-small,
.doc-small {
    background-image: url("../../../img/doc-small.svg");
}

.documents-list li.mobi-small,
.mobi-small {
    background-image: url("../../../img/mobi-small.svg");
}

.documents-list li.download-small,
.download-small {
    background-image: url("../../../img/download-small.svg");
}

.documents-list li.docx-small,
.docx-small {
    background-image: url("../../../img/docx-small.svg");
}

.documents-list li.xlsx-small,
.xlsx-small {
    background-image: url("../../../img/xlsx-small.svg");
}

.documents-list li.xls-small,
.xls-small {
    background-image: url("../../../img/xls-small.svg");
}

.documents-list li.zip-small,
.zip-small {
    background-image: url("../../../img/zip-small.svg");
}

.documents-list li.ppt-small,
.ppt-small {
    background-image: url("../../../img/ppt-small.svg");
}

.documents-list li.pptx-small,
.pptx-small {
    background-image: url("../../../img/pptx-small.svg");
}

.badge-light {
    color: #fff;
}

.badge-circle {
    width: 1rem;
    height: 1rem;
    border-radius: 0.8rem;
    font-size: 0.7rem;
    cursor: help;
}

.red-border-circle {
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: #b51a29;
}

#nav-main {
    .nav-link {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }

    .navbar-nav {
        .flaga-UE {
            width: 46px !important;
        }
    }
}

#nav-main,
#nav-secondary {
    &.navbar-collapse-visible {
        flex-basis: auto !important;

        @include media-breakpoint-up(xl) {
            display: inherit;

            .navbar-nav {
                flex-direction: row;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        border-top: 1px solid #dee2e6;
        margin-top: 0.125rem;

        .dropdown-item {
            white-space: initial;
        }
    }

    &.navbar-collapse-hiding {
        @include media-breakpoint-up(xxl) {
            display: none !important;

            &.show {
                display: none !important;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        background-color: #ffffff;
        border: 1px solid #dfdfdf;
        border-bottom-left-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;
        top: 2.3125rem;
        left: -0.0625rem;
        z-index: -4;
    }

    .dropdown-item {
        font-family: "Open Sans", sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
        padding: 0.6rem 1.5rem;

        &:hover,
        &:focus {
            background-color: #b51a29;
            color: white;
        }
    }

    p.dropdown-item {
        color: #565b5d;
        font-family: "Open Sans", sans-serif;
        font-size: 0.625rem;
        border-bottom: 1px solid #dfdfdf;
        margin-top: 1rem;
        margin-bottom: 0rem;
        text-transform: uppercase;
        padding-left: 0;
        padding-bottom: 0.1rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        width: auto;

        &:hover,
        &:focus {
            background-color: initial;
            color: #565b5d;
        }
    }

    .nav-item {
        &.dropdown {
            display: list-item;
        }
    }
}
#nav-main {
  border-top: none !important;
}

#nav-secondary {
    .nav-item {
        padding: 0;
        background: #fff;

        &:not(.d-none.d-md-block) {
            .nav-link {
                background: #fff;
                padding: 20px 0.75rem;
                border-bottom: none;
                border-right: none;

                &:focus {
                    position: relative;
                    z-index: 10;
                }
            }
        }
    }
}

#dropdown-download:after {
    display: none;
}

.icon-card {
    max-width: 8rem;
}

.icon-card .icon-round {
    background-color: var(--grey-200);
    border-radius: 50%;
    padding: 1.5rem;
    width: 4.6875rem;
    height: 4.6875rem;
}

.bg-white-square::before {
    content: "";
    height: 3rem;
    width: 3rem;
    background-color: #fff;
    display: block;
    position: relative;
    transform: rotate(45deg);
    top: -4.5rem;
    margin: 0 auto;
}

nav>ul>li {
    list-style-type: none;
}

.offer.border {
    border-color: var(--grey-100) !important;
}

.offer.border:hover {
    background-color: var(--grey-100);
}

.offer.border:not(:last-child) {
    border-bottom: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-hover:hover {
    background-color: #f2f2f2;
}

.accordion-head {
    background-image: url("../../../img/arrow-down.svg");
    background-size: 1.5625rem 1.5625rem;
    background-repeat: no-repeat;
    list-style-type: none;
    background-position: right 0.9375rem top 0.9375rem;
}

.accordion-head:not(.collapsed) {
    background-color: var(--grey-100);
    background-image: url("../../../img/arrow-up.svg");
    fill: red;
}

.accordion-content.show {
    background: none;
}

.card-docs:not(:last-child) {
    border-bottom: none !important;
}

.bootstrap-select {
    .dropdown-toggle {
        &::after {
            background-image: url("../../../img/arrow-down.svg");
            width: 1.25rem;
            height: 0.875rem;
            background-repeat: no-repeat;
            background-position: 5px 0.3125rem;
            border: none;
        }

        .filter-option-inner-inner {
            color: #000;
        }
    }

    .dropdown-menu {
        min-width: 100% !important;
        padding: 0;

        &.inner {
            li {
                padding: 0.25rem;

                a {
                    .text {
                        color: $black;
                        font-size: 1rem;
                    }
                }

                &.selected {
                    a {
                        .text {
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }

                &:hover,
                &:focus {
                    background-color: $lightGrey;
                }
            }
        }

        div {
            margin: 0;
        }
    }

    &.show {
        .dropdown-menu {
            display: block;
        }
    }
}

.select-css {
    background-image: url("../../../img/arrow-down.svg"), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

.grants-scroll-container {
    position: relative;
    max-width: 1366px;
}

.grants-scroll {
    overflow-x: hidden;
    padding-left: 5.625rem;
}

.grant-gradient-right {
    background: linear-gradient(to right, rgba(153, 153, 153, 0) 0%, rgba(154, 154, 154, 0) 1%, rgba(255, 255, 255, 1) 100%);
    height: 100%;
    width: 3.75rem;
    position: absolute;
    right: 0;
    z-index: 1;
}

.grant-gradient-left {
    background: linear-gradient(to left, rgba(153, 153, 153, 0) 0%, rgba(154, 154, 154, 0) 1%, rgba(255, 255, 255, 1) 100%);
    height: 100%;
    width: 3.75rem;
    position: absolute;
    left: 0;
    z-index: 1;
    display: none;
}

.bg-bell {
    background-color: #b61929;
    border-radius: 50%;
    width: 2.625rem;
    height: 2.625rem;
    padding: 0.625rem;
    cursor: pointer;
}

#bell-message {
    position: absolute;
    top: 3.875rem;
    right: 1.3125rem;
    background-color: $white;
    border-radius: 0.4em;
    z-index: 1;
}

.filter-element {
    display: block !important;
}

.tooltip-inner {
    min-width: 15.625rem;
    /* the minimum width */
    max-width: 31.25rem;
    padding: 1rem;
    color: #000;
    text-align: center;
    background-color: #f7f7f7;
    box-shadow: 0 0.6rem 3rem rgba(0, 0, 0, 0.25) !important;
}

.tooltip-inner table td,
.tooltip-inner table th {
    padding: 0.25rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #f7f7f7;
}

.tooltip-close-holder {
    background-color: #f7f7f7;

    .tooltip-close {
        padding: 1rem 1rem 0 0;
        border: none;
        background: none;
        margin: 0;
    }
}

.text-year {
    color: #949494;
    margin-bottom: -3rem;
}

.schedule-main:hover,
.schedule-default:hover {
    text-decoration: none;
}

.schedule-default:hover div {
    background-color: #dee2e6;
}

.schedule-main:hover p {
    color: white !important;
}

.schedule-default>div {
    border-left: 5px solid #dee2e6 !important;
    border-radius: 0.25rem;
}

.schedule-box:hover {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15) !important;
    background-color: rgba(2, 29, 39, 0.5);
}

a.schedule-link:hover {
    text-decoration: none !important;
}

.schedule-box:hover p {
    //color: #b51a29 !important;
    //text-decoration: none !important;
}

.news-link:hover div.row {
    background-color: #f8f9fa !important;
}

#devAreaNav,
#stickyAreaNav {
    top: 4.25rem;
    z-index: 3000;

    .nav-link {
        display: inline;
        padding: 0;
        text-transform: inherit;
        line-height: inherit;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        color: inherit;

        &.active {
            color: $primary;
        }
    }
}

#devArea .shadow,
#stickyArea .shadow {
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15) !important;
}

.publication-hover:hover img {
    opacity: 0.4;
    transition: 0.5s ease-in-out;
}

.publication-hover:hover p:not(.btn) {
    color: #b51a29 !important;
}

.publication-hover:hover .btn-outline-primary {
    color: #fff;
    background-color: #b51a29;
    border-color: #b51a29;
}

.btn-outline-primary:hover {
    .svg-outline-primary-hover {
        path {
            fill: #fff;
        }
    }
}

.bg-falka {
    background-image: url("../../../img/bg-falka.png");
    background-position: top center;
    background-repeat: no-repeat;
}

ul.falka-list li {
    background-image: url("../../../img/falka.svg");
    background-repeat: no-repeat;
    list-style-type: none;
    margin-bottom: 2rem;
    padding-top: 1rem;
}

ul.falka-list li>ul li {
    background-image: url("../../../img/falka2.svg");
    background-repeat: no-repeat;
    list-style-type: none;
    margin-bottom: 2rem;
    padding-top: 1rem;
}

.bg-img-poir {
    background-image: url("../../../img/bg-poir.jpg");
    background-size: cover;
    background-position: center top;
}

.bg-img-default {
    background-image: url("../../../img/bg-default.jpg");
    background-size: cover;
    background-position: center top;
}

.bg-img-popw {
    background-image: url("../../../img/bg-popw.jpg");
    background-size: cover;
    background-position: center top;
}

.bg-img-power {
    background-image: url("../../../img/bg-power.jpg");
    background-size: cover;
    background-position: center top;
}

.bg-knowledge {
    background-image: url("../../../img/bg-knowledge.jpg");
    background-size: cover;
    background-position: center top;
}

.bg-form-wide {
    background: url("../../../img/bg-form1.png") left bottom no-repeat, url("../../../img/bg-form2.png") right bottom no-repeat;
}

.bg-form-narrow {
    background: url("../../../img/bg-form1.png") left bottom no-repeat;
}

.knowledge-card {
    min-height: 6.25rem;
    min-width: 18.75rem;
    /*max-width:  21.875rem;*/
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, .1);
}

.knowledge-card.bg-white:hover {
    background-color: #b51a29 !important;
}

.knowledge-card:hover p {
    color: #fff;
}

.knowledge-card:hover path {
    fill: #fff;
}

.video-modal {
    max-width: 50rem;
}

.video-modal .modal-content {
    background-color: initial;
    border: none;
}

.video-modal .close {
    opacity: 1;
    font-weight: 300;
    text-shadow: none;
    padding-bottom: 2rem;
    font-size: 2rem;
}

.video-btn:hover {
    text-decoration: underline;
    cursor: pointer;
}

.bg-video {
    width: 100%;
    padding-top: 75.25%;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.video-play {
    left: calc(50% - 30px);
    top: calc(50% - 4rem - 30px);
    z-index: 100;
    opacity: 0.7;
}

.video-play.small {
    top: calc(50% - 0rem - 30px);
}

.video-btn:hover .video-play {
    opacity: 1;
}

.btn-search-left {
    background-color: #fff;
    border-left: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding-right: 0;
}

.btn-search-right {
    background-color: #fff;
    border-right: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.input-search {
    border-left: none;
    border-right: none;
}

.bg-event {
    background-color: #b51a29;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
}

.event-hover:hover {
    background-color: #f7f7f7;
}

a {
    .training-category {
        min-height: 4.6875rem;
    }

    .cooperation-category {
        min-height: 8.75rem;
    }

    &:hover,
    &:focus {

        .training-category,
        .cooperation-category {
            background-color: #b61929 !important;
            color: #fff;
        }
    }
}

.training-column {
    display: flex;
    flex-direction: column;

    .training-item {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .training-card {
            min-height: 12.1875rem;
            flex-grow: 1;

            .akademia-rating {
                font-family: "Montserrat";
                font-size: 1.375rem;
                font-weight: 600;
                margin-top: 0.1875rem;
                margin-right: 0.3125rem;
            }

            .akademia-fullname {
                min-height: 4.0625rem;
                //max-height: 4.0625rem;
                overflow: hidden;
            }
        }

        &:hover,
        &:focus {
            .training-card {
                background-color: #b61929 !important;

                p {
                    color: #fff !important;

                    .akademia-star path {
                        fill: white;
                        stroke: white;
                    }

                    .akademia-star-stroke path {
                        stroke: white;
                    }
                }
            }
        }
    }
}

.akademia-filters {
    .btn-group-toggle {
        .btn {
            background-color: white;
            border: 1px solid #ebe9eb;
            display: flex;
            margin: 0;
            padding: 0.5rem 1.5rem;
            font-weight: 500;
            line-height: 1.2;

            &.active {
                background-color: #036b98;
                background-image: url("../../../img/akademia-check.svg");
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: calc(100% - 6px);
                border: 1px solid #036b98;
                color: white;
            }

            &.inative {
                background-color: #e5e5e5;
                color: #323232;
                cursor: not-allowed;
            }

            &:hover {
                background-color: #036b98;
                border: 1px solid #ebe9eb;
                color: white;
                cursor: pointer;
            }

            &.focus {}
        }
    }

    li {
        padding: 0.5rem 0.15rem;
    }

    .separator {
        width: .25rem;
        background-color: white;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            width: 0.0625rem;
            height: 75%;
            background: #666466;
        }
    }

    &.filters-border {
        border-bottom: 4px solid #edaa1d;
    }
}

.akademia-filter.custom-select {
    width: auto;
    padding: 0 1rem;
    height: 2.375rem;
    min-width: 10.9375rem;
    cursor: pointer;
}

.training-img {
    height: calc(1rem + 180px);
    background-size: cover;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    position: relative;
    border: 1px solid #f0f6f5;

    .popular {
        background-color: #b61929 !important;
        color: #fff;
        text-transform: uppercase;
        width: auto;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 1rem;
        margin-bottom: 1rem;
        padding: 0.25rem 1rem;
    }
}

.bg-training-finansowanie {
    background-color: #edece4 !important;
    background-image: url("../../../img/bg-training.png");
    background-repeat: no-repeat;
    background-position: left;
}

.border-training-finansowanie {
    border: 1px solid #edece4;
}

.bg-trainng-marketing-i-sprzedaz {
    background-color: #ecf4f3 !important;
    background-image: url("../../../img/bg-training.png");
    background-repeat: no-repeat;
    background-position: left;
}

.border-training-marketing-i-sprzedaz {
    border: 1px solid #ecf4f3;
}

.bg-training-prawo {
    background-color: #e7e8ee !important;
    background-image: url("../../../img/bg-training.png");
    background-repeat: no-repeat;
    background-position: left;
}

.border-training-prawo {
    border: 1px solid #e7e8ee;
}

.bg-training-wsparcie-otoczenia-biznesu {
    background-color: #ecf4ee !important;
    background-image: url("../../../img/bg-training.png");
    background-repeat: no-repeat;
    background-position: left;
}

.border-training-wsparcie-otoczenia-biznesu {
    border: 1px solid #ecf4ee;
}

.bg-training-zarzadzanie {
    background-color: #eee7ea !important;
    background-image: url("../../../img/bg-training.png");
    background-repeat: no-repeat;
    background-position: left;
}

.border-training-zarzadzanie {
    border: 1px solid #eee7ea;
}

.border-training-kompetencje {
    border: 1px solid #e8e8e8;
}

.border-bottom-red-4 {
    border-bottom: 4px solid #b61928;
}

.table {
    margin: 0;
}

.table thead th {
    background-color: #b51a29;
    color: #fff;
    // border: none;
}

.table td,
.table th {
    border: 3px solid #fff;
}

.table tr:last-of-type td {
    //border-bottom: 3px solid #b51a29;
}

span.highlight {
    font-weight: bold;
    background-color: #b3f7b3;
}

#tags a {
    cursor: pointer;
}

// datetime picker
.sr-only {
    position: absolute;
    width: 0.0625rem;
    height: 0.0625rem;
    margin: -0.0625rem;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.min-height-250 {
    min-height: 15.625rem;
}

.btn:focus {
    box-shadow: 0 0 0 0.2rem $input-btn-focus-color;
}

.text-shadow {
    text-shadow: 2px 2px 2px #000000;
}

.checkbox-outline {
    &:checked {
        outline: 2px solid #fff;
    }
}

.open_widget {
    display: inline-block;

    .btn-outline-primary {
        border-width: 2px;
    }

    &:hover svg path {
        fill: white;
    }
}

/** new styles **/
body.featured-2023,
body.site-2023,
body.resources-2023,
body.events-2023,
body.event-2023,
body.parpnewscom-2023,
.force-new-features {

    section {
        scroll-margin-top: 7.5rem;
    }

    .bg-events-new {
        background-image: url("../../../img/najblizsze_wydarzenia_bg.jpg");

        .eventCard {
            border: none !important;
        }

    }

    .bg-events-new-dark {
        background-image: url("../../../img/bg-dark-event.jpg");

        .eventCard {
            border: none !important;
        }
    }

    .eventsHero {
        background-image: url("../../../img/bg-events.jpg");
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .eventHero {
        background-image: url("../../../img/bg-event.jpg");
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;

        .eventTag {
            border-radius: 10px 0.5rem;
            background: #C6C7C8;
            font-family: Calibri;
            font-weight: 700;
            padding: .5rem 1rem;
            font-size: 0.9375rem;
            margin-right: 0.625rem;
        }

        .eventRegister {
            border-radius: 1rem 0.5rem;
            color: white;
        }
    }

    .filters-round {
        border-radius: 25px 0.5rem;
    }

    .mod-parp-events {
        background-repeat: no-repeat;
        background-position-y: center;
        background-size: cover;

        &.no-bg {
            background-image: none;

            .eventCard {
                border: 1px solid #dee2e6;
            }
        }

        .slick-track {
            height: 100%;
            padding-bottom: 1rem;
        }

        .slick-list {
            z-index: 100;
        }

        .slick-slide {
            .eventCard {
                left: -.5rem;
            }
        }

        .slick-arrow {
            top: calc(50% - 42px);

            &:first-of-type {
                left: -2.125rem;
            }

            &:last-of-type {
                right: -2.125rem;
            }
        }

        .slick-disabled {
            display: none !important;
            opacity: 0;
            pointer-events: none;
        }

        .eventCard {
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            position: relative;
            width: 100%;

            .eventOnline {
                background-color: #B51A29;
                color: white;
                position: absolute;
                right: 0.3125rem;
                top: -0.625rem;
                padding: 0.5rem 1rem;
                font-family: Calibri;
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 700;
                z-index: 2
            }

            .eventOnlineTriangle {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 11px 17px;
                border-color: transparent transparent #771C25 transparent;
                position: absolute;
                top: -0.625rem;
                right: 6rem;

            }

            .eventDate {
                width: 5.5rem;
                border-top-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                color: white;
                padding: 0.625rem;

                .line {
                    height: 0.0625rem;
                    background-color: white;
                    width: 80%;
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }

                .eventDateCenter {
                    width: 1.8125rem;
                    text-align: center;
                }
            }

            .eventContent {
                flex: 1;

                p:first-child {
                    min-height: 5.625rem;
                }
            }

            .eventAlert {
                min-height: 3.0625rem;

                p {
                    min-height: 3.0625rem;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    border-bottom-right-radius: 1rem;
                    text-align: center;
                }
            }

            .misje {
                background-color: #0c7492;
            }

            .szkolenia {
                background-color: #b61928;
            }

            .spotkanie {
                background-color: #c45502;
            }

            .konferencja {
                background-color: #584991;
            }

            .transmisja {
                background-color: #278900;
            }

            .webinarium {
                background-color: #c84b27;
            }

            .konkursy {
                background-color: #b61928;
            }
        }

        .events-pagination {
            max-width: 100%;
            width: 100%;
            bottom: 0;
            display: flex;
            justify-content: space-between;
        }

        .btn-event-all:hover {
            background-color: #b51a29 !important;
        }
    }

    .eventCollapse.collapsed {
        svg {
            transform: rotate(-180deg);
        }
    }

    .modal-backdrop {
        z-index: 7777 !important;
    }

    .modalEvent {
        z-index: 9999;
    }


    .event-form-button {
        border-radius: 8px 0px !important;
        background: #B51A29 !important;
        margin-left: 0.25rem;
    }

    #event-program-list {

        p span strong {
            font-family: "Montserrat", sans-serif !important;
            font-weight: 600 !important;
            font-size: 1.125rem !important;
        }

        .border-top-program {
            @media (min-width: 576px) {
                border-top: none !important;
            }
        }
    }

    .was-validated {
        .modalEvent {
            .form-check-input:valid ~ .form-check-label
            {
                color: inherit;
            }
        }

        .valid-feedback {
            color: darken($success, 10%);
        }
    }

    @include media-breakpoint-down(md) {
        #nav-event-details .collapse {
            >div {
                padding: 0 0.4375rem;
            }

            .nav-item {
                &:not(:last-child) {
                    border-bottom: 1px solid #dee2e6;

                }
            }
        }
    }
}

body.featured-2023,
body.resources-2023,
body.site,
.force-new-features {
    h2 {
        font-family: Roboto, sans-serif;
        font-size: 2.375rem;
        font-weight: 400;
        line-height: 2.875rem;
    }

    .mod-news {
        @media (max-width: 767px) {
            .grid {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 1.25rem;
            }

            .ekspert {
                grid-area: 2/1;
            }

            .news-box-big {
                border-radius: 1rem;

                img {
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 1rem;
                }

                .news-intro {
                    display: none;
                }

                div.news-content {
                    padding: 1.75rem;
                }
            }
        }

        @media (min-width: 768px) {
            .grid {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 0.625rem;
            }

            .news-box-big {
                grid-column-start: span 2;
                width: 100%;
                height: 28.4375rem;
                border-radius: 1rem;

                img {
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 1rem;
                    height: 12.5rem;
                    object-fit: cover;
                    margin-bottom: 0.75rem;
                }

                .news-intro {
                    display: none;
                }

                .news-content {
                    padding: 0.9375rem;
                }
            }

            .ekspert {
                grid-area: 1/4;
            }
        }

        @media (min-width: 1176px) {
            .grid {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
            }

            .news-box-big {
                grid-row-start: span 2;
                width: 100%;
                height: 54.0625rem;
                border-radius: 1rem;

                img {
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 1rem;
                    height: 25rem;
                    object-fit: cover;
                    margin-bottom: 1.375rem;
                }

                .news-content {
                    padding: 1.75rem;
                }

                .news-intro {
                    display: block;
                }
            }

            .ekspert {
                height: 100%;
                min-height: 50%;
                grid-area: 1/5;
            }
        }

        .news-box-big {
            &:hover {
                background-color: #f0f0f0;

                a {
                    text-decoration: none;
                }
            }

            &.carousel {
                display: flex;
                flex-direction: column;

                &:has(.carousel-item:focus-visible) {
                  //noinspection CssOverwrittenProperties
                  outline: 2px solid #0060df;
                  //noinspection CssOverwrittenProperties
                  outline: -webkit-focus-ring-color auto 2px;
                }
            }

            .modnews-carousel-pagination {
                border-bottom-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }
        }

        .news-box {
            width: 100%;
            height: 100%;
            min-height: 1.5625rem;
            border-radius: .5rem;
            text-decoration: none;

            img {
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
                height: 12.5rem;
                object-fit: cover;
                margin-bottom: 0.75rem;
            }

            .news-content {
                padding: 0.9375rem;
            }

            &:hover {
                background-color: #f0f0f0;
            }
        }

        .ekspert {
            border-radius: .5rem;

            .ekspert-title {
                height: 9.375rem;
                object-fit: cover;
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
            }

            &:hover {
                background-color: #f0f0f0;

                a {
                    text-decoration: none;
                }
            }

            #carouselEkspert {
                //max-height: 23.75rem;
                //min-height: 23.75rem;

                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .card-body {
                    p.text-black {
                        height: 3.875rem;
                    }
                }
            }
        }

        #hotNewsItem {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .card-body {
                p.text-black {
                    height: 6.5625rem;
                }
            }
        }

        .modnews-carousel-pagination {
            height: 2.6875rem;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;

            .carousel-indicators.pagination {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-content: center;

                .page-item {
                    opacity: 1 !important;
                    background-color: transparent;

                    box-sizing: content-box;
                    flex: none;
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    margin-left: 0;
                    text-indent: initial;
                    cursor: pointer;
                    background-clip: initial;
                    border: none;
                    transition: none;

                    a, button {
                        padding: 0.375rem;
                        border: none;
                    }

                    &.active {
                        background-color: #e2e2e2;
                        text-decoration: none;

                        a, button {
                            background-color: #e2e2e2;
                        }
                    }

                    &:hover,
                    &:focus {
                        background-color: #e2e2e2;

                        a, button {
                            background-color: #e2e2e2;
                            padding: 0.375rem;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }


    .mod-static-cooperation {
        .transparent-line-left {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.44);
            height: 2.5625rem;
            //   position: absolute;
            bottom: 2.9375rem;

            @include media-breakpoint-down(sm) {
                bottom: -1.3125rem;
            }

            .wspolpraca-fala {
                width: 2.5625rem;
                position: absolute;
                bottom: 3rem;
                right: -1rem;
                z-index: 1;
            }
        }

        .transparent-line-right {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.44);
            height: 2.5625rem;
            position: absolute;
            bottom: 2.9375rem;

            @include media-breakpoint-down(sm) {
                bottom: -1.3125rem;
            }

            .wspolpraca-fala {
                width: 2.5625rem;
                position: absolute;
                left: 2.875rem;
                z-index: 1;
            }
        }

        .bg-wspolpraca {
            background-image: url("../../../img/wspolpraca_przedsiebiorstw_bg.jpg");
            background-size: cover;
            height: auto;

            @include media-breakpoint-down(sm) {
                height: 1.5625rem;
            }
        }
    }

    .business-steps {
        .falka {
            position: absolute;
            z-index: 2222;
            left: calc(50% - 40px);
            top: 19.6875rem;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &.mobile {
                display: block;
                bottom: 2.5rem;
                top: initial;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }

        .step-image {
            .gradient-left {
                width: 0.1875rem;
                background: rgb(255, 255, 255);
                background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                max-height: 22.5rem;
                min-height: 22.5rem;
                position: absolute;
                right: 0;
                top: 0;
            }

            img {
                max-height: 22.5rem;
                min-height: 22.5rem;
                object-fit: cover;
                width: 100%;
            }
        }

        .step-content {
            min-height: 17.1875rem;
            padding: 1.5rem 5rem;

            p {
                width: 75%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .gradient-right {
                width: 0.1875rem;
                background: rgb(255, 255, 255);
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }

            @include media-breakpoint-down(sm) {
                padding: 3.75rem 1rem;
            }
        }

        a {
            text-decoration: none;
            position: relative;
            height: 100%;

            &:hover {
                transform: scale(1.03);
                z-index: 1000;
                transition-duration: 700ms;
            }
        }
    }
}

.platformy-startowe {
    .jumbotron {
        background-image: url("../../../img/platformy-bg.jpg");
        background-size: cover;
        background-position: top center;

        @include media-breakpoint-up(md) {
            p {
                width: 70%;
            }
        }
    }

    .poradnik {
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

        img {
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
        }

        .title {
            &:after {
                content: "";
                width: 3.75rem;
                height: 0.125rem;
                background: #565b5d;
                position: absolute;
                left: 0;
                bottom: -0.625rem;
                z-index: 1;
            }
        }
    }
}

.realizujesz-projekt {
    .jumbotron {
        background-image: url("../../../img/realizujesz-projekt-bg.jpg");
        background-size: cover;
        background-position: top center;

        @include media-breakpoint-up(md) {
            p {
                width: 70%;
            }
        }
    }

    form.znajdz-program {
        ol>li>div {
            border-top-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            margin-top: 0.9375rem;
            margin-right: 1.875rem;

            @include media-breakpoint-down(sm) {
                margin-right: 0.9375rem;
            }
        }

        .form-control {
            padding: 0.75rem;
            border-radius: .5rem;
            height: 100%;
        }
    }
}

.sukcesy-beneficjentow {
    .sukces {
        img {
            max-height: 13.75rem;
            object-fit: cover;
        }

        .title:after {
            content: "";
            width: 3.75rem;
            height: 0.125rem;
            background: #565b5d;
            position: absolute;
            left: 0;
            bottom: -0.625rem;
            z-index: 1;
        }
    }
}

section.linki {
    .container-fluid {
        background-image: url("../../../img/linki-bg.jpg");
        background-size: cover;
        background-position: top center;
    }
}

.border-top-left-15 {
    border-top-left-radius: 1rem;
}

.border-bottom-right-15 {
    border-bottom-right-radius: 1rem;
}

.bg-disabled {
    background-color: #4a4a4a !important;
    border-color: #4a4a4a !important;
}


/** BUR Content (bez navbar-footer **/


.featured-2023.bur-2023 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #212529;
    line-height: 1.5;

    h1 {
        color: #4A4A4A;
        font-size: 1.25rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
            font-size: 2.9375rem;
        }
    }

    h2 {
        font-size: 1.125rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        position: relative;
        line-height: 1.5;

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }

        &.line {
            span {
                padding: 0 .5rem;
                left: .5rem;
                position: relative;

                @include media-breakpoint-up(lg) {
                    padding: 0 1rem;
                    left: 2.5rem;
                }
            }

            &:before {
                border-top: 1px solid #D9D9D9;
                content: "";
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: 0;
            }
        }
    }

    h3 {
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 130%;

        @include media-breakpoint-up(lg) {
            font-size: 1.5rem;
        }
    }

    h4.formy {
        font-size: 0.875rem;
        text-transform: uppercase;
    }

    .breadcrumb {
        position: initial;
        font-size: 0.75rem;
    }

    .breadcrumb-item {
        padding-left: 0.25rem;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        top: 0.0625rem;
        position: relative;
        padding-right: 0.25rem;
        color: #6E6E6E;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.77595 12.8343C4.4989 13.1422 4.52393 13.6164 4.83186 13.8935C5.13978 14.1705 5.614 14.1455 5.89104 13.8376L4.77595 12.8343ZM10.1335 8.00094L10.691 8.50257C10.9476 8.21737 10.9476 7.7845 10.691 7.4993L10.1335 8.00094ZM5.89104 2.1643C5.614 1.85637 5.13978 1.83134 4.83186 2.10839C4.52393 2.38543 4.4989 2.85965 4.77595 3.16757L5.89104 2.1643ZM5.89104 13.8376L10.691 8.50257L9.57595 7.4993L4.77595 12.8343L5.89104 13.8376ZM10.691 7.4993L8.29104 4.8318L7.17595 5.83507L9.57595 8.50257L10.691 7.4993ZM8.29104 4.8318L5.89104 2.1643L4.77595 3.16757L7.17595 5.83507L8.29104 4.8318Z' fill='%23212529'/%3E%3C/svg%3E%0A");
    }

    .bur-box {
        background-color: #F0F0F0;
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: 1.5rem;

        ul {
            li {
                margin-bottom: 0.25rem;

                a {
                    text-decoration: underline;
                }
            }
        }

        h4 {
            text-transform: uppercase;
            color: #0C7492;
            font-size: 1rem;
        }

    }

    .text-bur-blue {
        color: #0C7492;
    }

    .bg-bur-blue {
        background-color: #0C7492;
        color: white;
    }

    .bur-radius {
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }


    .bur-news {
        .results {
            img {
                height: 100%;
                object-fit: cover;
            }

            .separator {
                width: 3.75rem;
                height: 0.125rem;
                background-color: #727272;
                margin: 0.75rem 0;
            }
        }
    }

    .bur-big-news {

        .news-box,
        .news-box-big {
            border-radius: 0;
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;

            img {
                border-radius: 0;
                border-top-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }
        }
    }

    .bur-pagination {
        .page-item {

            .page-link {
                border-radius: 0.3125rem;
            }

            &.active {
                .page-link {
                    color: white;
                    background-color: #b51a29;
                }
            }
        }
    }

    .bur-download-list {
        li {
            margin-bottom: 1rem;

            span {
                margin-left: 2.25rem;
            }
        }
    }
}

.video-carousel {
    .slick-list {
        margin: 0 1rem;
    }

    gap: 0.75rem;
    position: relative;
    max-height: 21.125rem;


    .arrow-left {
        position: absolute;
        left: -1%;
        top: 50%;
    }

    .arrow-right {
        position: absolute;
        right: -1%;
        top: 50%;

    }

    .video-card {
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.20);
        min-width: 230px !important;
        width: 17rem;
        background-color: #fff;

        &:hover {
            text-decoration: none;
            background-color: #f0f0f0;

            .video-description {
                background-color: #f0f0f0 !important;
            }
        }

        &.akademia {
            border: none;
            border-bottom-left-radius: 1rem;

            &:hover {
                background-color: #b61929 !important;

                .video-description {
                    background-color: inherit !important;
                }

                p {
                    color: #fff !important;
                }
            }

            .bg-video-2023 {
                border-top-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .video-description {
                border-bottom-left-radius: 1rem;
            }
        }

        &.slick-slide {
            margin: 0 0.3rem;
        }

        .bg-video-2023 {
            background-size: cover;
            background-position: center;
            height: 11.9375rem;
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            position: relative;
            background-color: white;

            svg.play {
                position: absolute;
                left: calc(50% - 20px);
                top: calc(50% - 20px);
            }

            .video-category {
                border-top-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                background-color: #B51928;
                color: white;
                display: inline-block;
            }

            .parp-category {
                border-top-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                background-color: #C6C7C8;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .video-description {
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            position: relative;
            min-height: 8.75rem;

            display: flex;
            flex-direction: column;

            .video-time,
            .video-more {
                //position: absolute;
                //bottom: 1rem;
                //right: 1rem;
            }


        }


    }
}

body.events-2023 {
    .select-order li.selected.active {
        background-color: var(--primary);
    }
}

body.event-2023 {

    .event-details-details,
    .container-flex-logo {
        max-width: 53.875rem;
    }

    .belka-logotypow {
        @include media-breakpoint-down(sm) {
            width: 70%;
        }
    }

    .map-object iframe {
        width: 100%;
    }
}
.events-slick-new {
  .slick-track {
    //display: flex !important;
    padding-top: 1rem !important;
  }
  .slick-slide {
    //height: inherit !important;

    .slide {
      //height: 100% !important;
    }
  }

  .eventCard {
    min-height: 100%;
  }
}


.video-2023,
.videolist-2023,
.player,
main.video {
    @include media-breakpoint-down(md) {
        img.falki {
            width: 3rem;
        }
    }

    .select-order li.selected.active .text {
        color: #fff;
    }

    #video-list-filters {
        z-index: 4999;
    }

    .container-flex-logo {
        flex-direction: column;
        margin: auto -0.625rem;

        img {
            width: 100%;
            max-width: 1146px;
        }
    }

    .video-container {
        position: relative;
        padding-bottom: 53%;
        padding-top: 1.875rem;
        height: 0;
        overflow: hidden;
        margin-bottom: 1rem;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-category,
    .parp-category {
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background-color: #C6C7C8;

    }

    

    .video-hero {
        background-image: url("../../../img/video-bg-mobile.jpg");
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-up(md) {
            background-image: url("../../../img/video-bg.jpg");
        }

        @include media-breakpoint-down(md) {

            h1,
            p.text-rb-42 {
                font-size: 1.5rem !important;
            }


        }
    }

    .videos-container {
        gap: 0.75rem;
        position: relative;

        .arrow-left {
            position: absolute;
            left: 1.875rem;
        }

        .arrow-right {
            position: absolute;
            right: 1.875rem;
        }
    }

    .video-card {
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.20);
        width: 17rem;

        background-color: #fff;

        &:hover {
            text-decoration: none;
            background-color: #f0f0f0;

            .video-description {
                background-color: #f0f0f0 !important;
            }
        }

        @include media-breakpoint-down(sm) {
            max-width: none;
        }

        &.akademia {
            border: none;
        }

        .bg-video-2023 {
            background-size: cover;
            background-position: center;
            height: 11.9375rem;
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            position: relative;
            background-color: white;

            svg.play {
                position: absolute;
                left: calc(50% - 20px);
                top: calc(50% - 20px);
            }

            .video-category {
                border-top-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                background-color: #B51928;
                color: white;
                display: inline-block;
            }

            .parp-category {
                border-top-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                background-color: #C6C7C8;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .video-description {
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            position: relative;
            min-height: 8.75rem;

            display: flex;
            flex-direction: column;

            .video-time,
            .video-more {
                //position: absolute;
                //bottom: 1rem;
                //right: 1rem;
            }
        }

    }

    #video-filter-form,
    #video-filter-modal {
        ul {
            list-style: none;

            li {
                max-width: 9.1875rem;

                .custom-control {
                    &.custom-radio {
                        .custom-control-label {
                            &.page-link {
                                background-color: transparent;
                                border: none;

                                &::before {
                                    left: initial;
                                }

                                &::after {
                                    left: initial;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {

        #grants-filter-form .card.bg-light-grey,
        #video-filter-modal-form .card.bg-light-grey {
            //background-color: white !important;
        }

        #video-filter-modal-form .card.bg-light-grey {
            border-radius: 0;
        }

        .video-selected-filter #active-filters .select-filters-label {
            align-items: center;
        }
    }
}

/** End BUR Content (bez navbar-footer **/
