@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .position-sticky {
    position: relative !important;
    top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  #lastAdded {
    .bg-report, .bg-guide, .bg-article {
      .d-flex.flex-column.justify-content-between.m-3 {
        width: 550px;
      }
    }
  }

  #grants-filter-form ul li span {
    margin-top: 30px !important;
    width: 130px;
  }
  #funding .card img,
  #trainings-cards .card img,
  #publications .card img,
  .card img {
    height: 181px;
  }
  #recomended .card {
    background: #f2f2f2;
  }
  #publications .card-publication img {
    height: 250px;
  }
  #publications #recomended img {
    height: 284px;
  }
  #publications #recomended img.bigger {
    height: 700px;
  }

  .img-bur {
    height: 39px;
  }

  #funding {
    #grants-filter-collapse {
      #grants-filter-form {
        ul {
          li {
            .custom-control {
              &.custom-radio {
                .custom-control-label {
                  &.page-link {
                    &::before,
                    &::after {
                      left: 50%;
                      margin-left: -($custom-control-indicator-size / 2);
                    }
                  }
                }
              }
            }
          }

          &.pagination {
            display: block !important;

            li {
              display: block !important;
              float: left;

              label {
                display: inline-block !important;
                word-wrap: normal;
              }

              &:nth-child(1) {
                label {
                  width: 100px;
                  margin-left: 20px
                }
              }

              &:nth-child(2) {
                label {
                  width: 160px;
                }
              }

              &:nth-child(3) {
                label {
                  width: 121px;
                }
              }

              &:nth-child(4) {
                label {
                  width: 142px;
                }
              }

              &:nth-child(5) {
                label {
                  width: 105px;
                }
              }

              &:nth-child(6) {
                label {
                  width: 118px;
                }
              }

              &:nth-child(7) {
                label {
                  width: 135px;
                }
              }

              &:nth-child(8) {
                label {
                  width: 125px;
                }
              }

              &:nth-child(9) {
                label {
                  width: 125px;
                }
              }
            }
          }
        }
      }
    }
  }
  #event-list {
    .event-type-list {
      .btn {
        &.active {
          img {
            border: 3px solid red;
          }

          &:nth-child(1) {
            img {
              border-color: blue;
            }
          }
        }
      }
    }

    #event-list-results {
      a {
        .row {
          > div {
            > p {
              width: 100%;
            }
          }

          .flex-column {
            img.flex-row {
              flex-shrink: 0 !important;
            }
          }
        }
      }
    }
  }
  .bg-event > .col-12 > .text-mm-28 {
    width: 100%;
  }
  .bg-event > .row > .col-12 > .text-mm-28 {
    width: 100%;
  }
  #tabs-author {
    #tabs-author-speeches {
      a {
        .row {
          > div {
            > p {
              width: 100%;
            }
          }

          .flex-column {
            img.flex-row {
              flex-shrink: 0 !important;
            }
          }
        }
      }
    }
  }
  #devArea {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  #devArea,
  #stickyArea {
    p {
      max-width: 100% !important;
    }
  }
  #businessSteps {
    img {
      flex-shrink: 0 !important;
    }
  }
  #devAreaNav,
  #stickyAreaNav {
    display: none !important;
  }
  #infoline-form {
    display: block !important;
  }
  .grant-scroll-left,
  .grant-scroll-right {
    img {
      width: 40px;
      height: 50px;
    }
  }
  .infolinia .bg-form-narrow,
  .intercessor .bg-form-narrow {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
      // this looks stupid, but seems to work
      flex-basis: auto !important;
    }
  }

  // ikonki
  img {
    &[src$="/red-pdf.svg"] {
      width: 41px;
      height: 40px;
    }

    &[src$="/red-mobi.svg"] {
      width: 40px;
      height: 40px;
    }

    &[src$="/red-epub.svg"] {
      width: 31px;
      height: 40px;
    }
  }
}
