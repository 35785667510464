/* min breakpoints */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .custom-control {
    .custom-control-label {
      &::before,
      &::after {
        top: 0.35rem !important;
      }

      &:hover {
        color: $red;
        background-color: transparent;
      }
    }

    .custom-control-input {
      &[disabled],
      &:disabled {
        ~ .custom-control-label {
          &:hover {
            color: $custom-control-label-disabled-color;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

/* max breakpoints */

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
  .custom-control {
    &.custom-radio {
      input:checked {
        ~ label {
          &.custom-control-label {
            color: $red;
            background-color: $gray-200;
          }
        }
      }
    }

    &.custom-checkbox {
      input:checked {
        ~ label {
          &.custom-control-label {
            color: $red;
            background-color: $gray-200;
          }
        }
      }
    }

    label {
      border: none;
      font-weight: 500;
      font-size: 0.875rem;

      &.custom-control-label {
        &::before {
          top: 0.35rem !important;
          left: 0.25rem !important;
        }

        &::after {
          top: 0.35rem !important;
          left: 0.25rem !important;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
}
