/* min breakpoints */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .openSearch svg {
    top: 9px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .openSearch svg {
    top: -14px;
  }

  .grants-scroll-container > .row > .col-xl-3 {
    flex: 0 0 23%;
    max-width: 23%;
  }

  .navbar-toggler {
    order: 10;
  }
}

@media (min-width: 1600px) {
}

/* max breakpoints */

@media (max-width: 375px) {
  .tooltip-inner {
    max-width: 260px;
    overflow-x: auto;
  }
}

@media (max-width: 575.98px) {
  h2 {
    font-size: 2.1rem;
  }

  .btn-sm-block {
    display: block;
    width: 100%;
  }

  #grantDates .card-body .text-bb-20 {
    font-size: 1.1rem !important;
  }

  #businessStart,
  #businessDev,
  #businessEnd,
  #businessExtra {
    min-height: 0;
  }

  .bg-event {
    background-image: none !important;
  }
}

@media (max-width: 767.98px) {
  #bell-message {
    width: calc(100% - 48px);
    top: 18px;
    right: 48px;
  }

  div.eventList > div {
    border: none !important;
  }

  .card-scroll {
    flex-wrap: wrap;
    overflow-x: none;
  }

  .card-scroll-left,
  .card-scroll-right {
    display: none;
  }

  h2#opis {
    font-size: 1.125rem;
  }

  #nav-grant ul {
    width: 100%;
    margin: 10px;
  }

  #nav-grant .nav-link.active {
    color: var(--primary);
    border: none;
  }

  #nav-grant .btn {
    border: none;
  }

  #nav-grant .nav-item {
    border-bottom: 1px solid #dee2e6 !important;
  }

  #nav-grant li:last-child {
    border-bottom: 1px solid #757575 !important;
  }

  #nav-grant li:first-child {
    border-top: 1px solid #757575 !important;
  }

  #nav-grant .nav-link,
  #nav-grant .nav-link.active {
    padding: 10px 15px;
  }

  #nav-grant {
    position: relative !important;
    top: 0;
  }

  #nav-grant .navbar-collapse {
    align-items: initial;
  }

  #lastAdded div.col-12 {
    min-height: 250px;
  }

  .eventCard {
    height: auto !important;
  }

  #nav-author .nav-link,
  #nav-author .nav-link.active {
    padding: 20px 0 8px 0;
  }

  #nav-author {
    border: none;
  }

  #businessStart,
  #businessDev,
  #businessEnd,
  #businessExtra {
    min-height: 0;

    &.knowledge {
      &:not(.active) {
        margin-top: 0;
        margin-bottom: 0;
      }

      &.active {
        min-height: auto;

        &:after {
          content: none;
        }
      }
    }

    > div {
      min-height: 150px;
    }
  }

  // // Nie jestem pewien czemu to to, ale to psuje eventy:
  // .dropdown.bootstrap-select {
  // 	width: 100% !important;
  // }
}

@media (max-width: 991.98px) {
  html,
  body {
    scroll-behavior: smooth;
  }

  #businessStart {
    &.knowledge {
      &.active {
        &:after {
          content: none;
        }
      }
    }
  }
  .formats {
    border: none !important;
  }
  .grants-scroll-container {
    .grants-scroll {
      padding-left: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  //body {
  //	padding-top: 0;
  //}

  .navbar-toggler {
    padding-top: 12px;
    padding-bottom: 20px;
  }

  #devAreaNav,
  #stickyAreaNav {
    top: 0;
  }

  #nav-main,
  #nav-secondary {
    .dropdown-menu {
      border: none;
    }
  }

  .navbar.fixed-top {
    position: relative;
    padding-top: 0;
    overflow: hidden;
  }

  .navbar {
    overflow-y: auto;
  }

  .nav-item.small-item .nav-link {
    border-bottom: none;
  }

  .navbar-nav.navbar-small {
    flex-direction: row;
    height: 100%;
    max-height: 62px;
  }

  .navbar-small .nav-search svg {
    /*top: 20px;*/
  }

  .show > .nav-link {
    background: #fff;
  }

  .dropdown-item {
    padding: 8px 0;
    padding-left: 1.5rem;
  }

  p.dropdown-item {
    margin-bottom: 0;
  }

  .dropdown-menu.show {
    /*margin-top: -1px;*/
  }

  .dropdown-menu > div {
    border: none;
    margin-left: 12px;
    margin-right: 12px;
  }

  .dropdown-menu > div > div.border-right {
    border: none !important;
  }

  .form-search {
    max-width: 100%;
  }
}

@include media-breakpoint-down(lg) {
  .navbar .navbar-nav .nav-link {
    padding-left: $navbar-nav-link-padding-x;
    padding-right: $navbar-nav-link-padding-x;
  }
  .openSearchLi .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .parp-logo {
    margin-left: 1rem !important;
  }
}

//@include media-breakpoint-up(lg) {
//  .navbar .navbar-nav .nav-link {
//    padding-left: $navbar-nav-link-padding-x;
//    padding-right: $navbar-nav-link-padding-x;
//  }
//
//}
@include media-breakpoint-down(xl) {
  .openSearchLi {
    padding-left: $navbar-nav-link-padding-x !important;
    padding-right: $navbar-nav-link-padding-x !important;
    height: 52px;
  }
}

html[lang="en"] {
  @include media-breakpoint-down(xl) {
    .navbar .navbar-nav.navbar-small .nav-link {
      padding-left: $navbar-nav-link-padding-x;
      padding-right: $navbar-nav-link-padding-x;
    }

    .navbar-small {
      position: absolute;
      right: 40px;
      overflow-y: hidden;
    }
    //.parp-logo {
    //  width: 50%;
    //  margin-left: 1.5rem !important;
    //}
  }

  @include media-breakpoint-down(lg) {
    .navbar-small {
      top: 0;
    }
  }
}
