#publications {
  div.shadow {
    border: 1px solid #ccc;
    box-shadow: 10px 10px 0px 0px rgba(143, 143, 143, 1) !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .container-fluid.shadow {
    box-shadow: 0px 5px 5px 0px rgba(228, 228, 228, 1) !important;
  }

  .card {
    border: none;
  }

  #filter-publications {
    form {
      .input-group {
        input.form-control {
          height: auto;
        }
      }
    }

    #publications-filters {
      .dropdown.filter-element:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
}

.publication-shadow {
  border: 1px solid #ccc;
  box-shadow: 5px 5px 0px 0px rgba(143, 143, 143, 1) !important;
  margin-right: 5px;
  margin-bottom: 5px;
}

.card-publication .card-cover {
  min-height: 233px;
}
