.faq-widget {
  .widget-search-form {
    .widget-search-form-label {
      width: 100%;

      .form-search {
        width: 100%;
      }
    }
  }

  .single-checkbox__description {
    p {
      &:not(:last-of-type) {
        &:after {
          content: "*";
          color: #B61929;
          padding: 0 0 0 2px;
        }
      }
    }
  }

  #callback-form-timeslot, #callback-form-day {
    height: 150px;
  }
}
