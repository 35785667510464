#grant {
  // background-image: url('../../../img/popw-small.jpg');
  // background-size: auto;
  // background-position: right top;
  // background-repeat: no-repeat;
  background-color: #ededed;

  h1 {
    font-family: "NovelPro";
    font-weight: 600;
    font-size: 2rem;
    color: #000;
  }

  h2 {
    font-family: "NovelPro";
    font-weight: 600;
    font-size: 2.38rem;
    color: #000;
  }

  h3 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.25rem;
  }

  .timeline {
    .badge {
      font-size: 25%;
      width: 8px;
      height: 8px;
      border-radius: 6px;
    }

    p {
      font-size: 0.01rem;
    }
  }

  .grantCard {
    border-top: 4px solid #b61928;

    ul {
      list-style: none;
    }
  }

  .label {
    font-family: "Calibri";
    font-weight: 500;
    font-size: 0.85rem;
  }

  .value {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1rem;
    color: #000;
  }

  #grant-header {
    position: relative;
    z-index: 100;
  }

  picture.belka-logotypow {
    text-align: center;
  }
}
