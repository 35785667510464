/* min breakpoints */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  #event-list {
    .organizer-list {
      max-height: 14.5rem;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

/* max breakpoints */

@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
}
