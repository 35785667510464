
#lc {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  /**box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;**/
  opacity: 1;
}

#lc-minimized {
  width: 4em;
  height: 4em;
  background-color: #0d47a1;
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  z-index: 100;
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
}

#lc-minimized:hover {
  cursor: pointer;
}

#lc-minimized.minimized {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

#lc > div:first-child {
  border-radius: 10px 10px 0 0;
}

#lc > div:last-child,
#message-input,
#send-button {
  border-radius: 0 0 10px 10px;
  background: none;
  border: 0;
}

#lc.minimized {
  -webkit-transform: scale(0.8) translate(10%, 30%);
  -ms-transform: scale(0.8) translate(10%, 30%);
  transform: scale(0.8) translate(10%, 30%);
  opacity: 0;
}

#back,
#minimize {
  color: #fff;
  padding: 0;
}

#lc.minimized #minimize {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

#chat {
  -ms-flex-positive: 1;
  flex-grow: 1;
  /**background-color: #e9eef4;*/
  overflow-y: auto;
  position: relative;
  padding: 20px 0;
}

.chat-item {
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  padding: 2em 1em;

  border-bottom: 1px solid #dce1e6;
}

.chat-item:hover {
  cursor: pointer;
  background-color: #d9e1ea;
}

.chat-item-content {
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;

  color: #636f7e;
}

.chat-item-content-row {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;

  line-height: 1.5em;
}

.chat-item-content-authors {
  -ms-flex-positive: 1;
  flex-grow: 1;

  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.chat-item-content-text {
  font-size: 0.875em;
}

.chat-item-content > div:first-child {
  display: -ms-flexbox;
  display: flex;

  opacity: 0.6;
  font-size: 0.75em;
}

.message-list {
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: none;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #e9eef4;
  overflow-y: auto;
  min-height: 0px;
  position: relative;
}

#lc-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  line-height: 3em;
  padding: 0.5em 1em;
  height: 4em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  color: #5a6976;
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
}

#lc-header div {
  line-height: 3em;
}

#message-input {
  font-size: 1em;
  padding: 0 1em;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border: 0;
  resize: none;
  height: 3em;
}

#message-input::-webkit-input-placeholder {
  color: #b2bbc6;
}

#message-input::-moz-placeholder {
  color: #b2bbc6;
}

#message-input:-ms-input-placeholder {
  color: #b2bbc6;
}

#message-input::placeholder {
  color: #b2bbc6;
}

#lc .message-container {
  margin-bottom: 0.5em;
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#lc .message {
  background-color: #F3F4F5;
  padding: 0.9em;
  border-radius: 13px !important;
  border-bottom-right-radius: 1px !important;
  color: #000;
  /**box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px;**/
  word-wrap: break-word;
  max-width: 100%;
}

.message-container.agent {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.message-container.customer {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#lc .message-container.agent .message {
  background-color: #fff;
  border-radius: 13px !important;
  border-top-left-radius: 1px !important;
  color: #000;
  border: 1px solid #F3F4F5;
}

.message-container.system {
  -ms-flex-item-align: center;
  align-self: center;
}

.message-container.system .message {
  border-radius: 0;
  color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.agent-avatar {
  width: 2.4em;
  height: auto;
  border-radius: 50%;
  display: block;
  margin-right: 0.5em;
}

.customer-letter-wrapper {
  width: 2.4em;
  height: auto;
  border-radius: 50%;
  display: block;
  margin-right: 0.5em;

  background-color: #e6958d;
}

.customer-letter {
  text-align: center;
  display: block;
  line-height: 2.4em;
  color: #fff;
}

/*#footer {
	background-color: #fff;
	flex-shrink: 0;
	height: 4em;
}*/

#start-chat-button {

}

#textarea-wrapper {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

#send-button {
  background-color: #fff;
  color: #000000;
  font-size: 1em;
}

#offline-message {
  text-align: center;
  background-color: #fff;
  border-radius: 0.5em;
  padding: 0.5em;
  color: #5a6976;
  position: absolute;
  top: 1em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

#offline-message.hide {
  opacity: 0;
}

.offline-icon {
  font-size: 2em;
}

.resizable-svg {
  width: 100%;
  height: auto;
}

#lc-loader, .callback-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 3em;

  .circle {
    -webkit-transform-origin: 36px 36px;
    -ms-transform-origin: 36px 36px;
    transform-origin: 36px 36px;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
