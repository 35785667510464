body:not(.is-ie) {
  #stickyAreaNav,
  #devAreaNav {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    margin-top: -80px;
    visibility: hidden;

    &.is-sticky {
      visibility: visible;
    }

    > .menu {
      min-height: 75px;
      padding: 0.5rem !important;

      &.akademia-menu {
        padding: 0 !important;

        .akademia-logo {
          background-color: white;
          height: 115px;
          display: grid;
          justify-items: center;
          align-items: center;
          width: 30%;
          transform: skewX(335deg);
          position: relative;
          left: -35px;
          border-right: 4px solid #ddd;
          min-width: 280px;

          img {
            width: 150px;
            transform: skewX(25deg);
          }

          @media screen and (max-width: 600px) {
            border-right: 0;
            background-color: initial;
            width: 100px;
            min-width: 100px;
            left: 10px;
          }
        }
      }
    }

    > .menu-secondary {
    }

    .sticky-hide {
      display: none !important;
    }

    p {
      margin: 0 !important;
      line-height: 1;
      font-size: 13px !important;

      &.description {
        display: none;
      }

      &:hover {
        color: #b51a29 !important;
      }
    }

    .nav-link {
      &.active {
        color: $primary !important;
        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      .bg-white {
        background-color: initial !important;
      }
    }

    .accordion-head {
      min-height: 45px;
    }
  }

  #stickyAreaNav {
    &.py-5 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  #devAreaNav {
    .tileDev {
      box-shadow: initial !important;
      background-color: initial !important;
      margin: 0 !important;

      &:hover {
        p {
          color: #b51a29 !important;
        }
      }
    }

    h2 {
      display: none;
    }

    .tileStart,
    .tileDev,
    .tileEnd {
      &.h-100 {
        height: initial !important;
        padding: 0rem !important;
      }
    }
  }
}
