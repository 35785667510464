@import "~@fancyapps/fancybox/src/css/core.css";
@import "~@fancyapps/fancybox/src/css/fullscreen.css";
@import "~@fancyapps/fancybox/src/css/share.css";
@import "~@fancyapps/fancybox/src/css/slideshow.css";
@import "~@fancyapps/fancybox/src/css/thumbs.css";

.fancybox-toolbar {
  .fancybox-button {
    &:not(.fancybox-button--close) {
      display: none !important;
    }
  }
}
