#infoline-form {
  .row {
    flex-direction: column;
    margin: 0;
  }

  .form-inline {
    display: block;

    label {
      width: auto;
      margin-right: 0.5rem;
    }

    .nonform-control {
      width: auto;
    }
  }

  .form-group {
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-left: 0;
    }
  }

  .form-control {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e1e1;
    padding-left: 0;
  }

  .form-group-actions {
    padding-top: 2rem;
    text-align: center;

    .btn {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  span[data-toggle="tooltip"] {
    display: inline !important;
  }

  input[type="checkbox"] {
    margin-left: 0.5rem;
  }

  .control-label {
    span.hasTooltip {
      display: block !important;
    }
  }

  // #zglaszajacy {}
  #sprawa {
    #zalaczniki {
      padding-top: 2rem;
    }
  }

  #zgoda {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}
