/*
Zdanowski postanowił poedytować sobie podstawowy kod Bootstrapa
 */

.blockquote-footer {
  &:before {
    content: "— ";
  }
}

/*
.text-primary {
  color: inherit !important;
}
*/

a {
  &.text-primary {
    &:hover,
    &:focus {
      color: inherit !important;
    }
  }
}
