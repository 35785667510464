.has-magnifying-glass {
  padding-top: 5rem;

  &.heartbeat {
    &::before {
      animation: heartbeat 1.5s ease-in-out infinite both;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2.5rem;
    width: 5rem;
    height: 5rem;
    background-size: 5rem 5rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2244%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%23" + color-value($red) + "%22%20d%3D%22M42.773%2042.773a4.12%204.12%200%200%201-5.83%200l-10.091-10.09a19.44%2019.44%200%200%200%205.831-5.831l10.09%2010.09a4.122%204.122%200%200%201%200%205.831zm-26.28-9.787C7.385%2032.986%200%2025.601%200%2016.493%200%207.385%207.385%200%2016.493%200c9.108%200%2016.493%207.385%2016.493%2016.493%200%209.108-7.385%2016.493-16.493%2016.493zm0-28.863c-6.822%200-12.37%205.548-12.37%2012.37%200%206.821%205.548%2012.37%2012.37%2012.37%206.821%200%2012.37-5.549%2012.37-12.37%200-6.822-5.549-12.37-12.37-12.37zm-6.872%2012.37H6.872c0-5.305%204.317-9.622%209.621-9.622v2.75a6.88%206.88%200%200%200-6.872%206.872z%22%2F%3E%3C%2Fsvg%3E");
    content: '';
  }
}
